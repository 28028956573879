<template>
  <div class="editTable">
    <el-table
      ref="mutipleTable"
      border
      v-loading="config.loading"
      header-cell-class-name="table-header"
      v-bind="$attrs"
      :data="config.list"
      :height="config.tableHeight"
      @selection-change="selectionChange"
      @select="select"
      @select-all="selectAll"
      @row-click="rowClick"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      :show-summary="Boolean(config.summary) || false"
      :summary-method="getSummaries"
    >
      <!--region 选择框 -->
      <el-table-column
        v-if="config.mutiSelect"
        :reserve-selection="config.reserveSelection"
        type="selection"
        align="center"
        width="55"
        :selectable="config.selectable"
        fixed
      />
      <el-table-column
        v-if="config.radioSelect && !config.mutiSelect"
        width="55"
        fixed
        label="选择"
        align="center"
        class-name="radioSelect"
        header-align="center"
      >
        <template slot-scope="{ $index, row }">
          <el-radio
            :disabled="
              config.selectable ? config.selectable(row, $index) : undefined
            "
            v-model="config.radioSelect"
            :label="row[config.rowKey]"
          >
            {{ '' }}
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column align="center" width="80" :label="config.tableCellLabel" v-if="!config.status">
        <template v-slot="scope">
          <i
            v-if="scope.row.hoverRow && !config.isNoAddBtn"
            @click.stop.self="handleTableRow('push', scope.$index)"
            class="el-icon-circle-plus operatePush"
          ></i>
          <i
            v-if="scope.row.hoverRow && !config.isNoDelBtn"
            @click.stop.self="handleTableRow('del', scope.$index)"
            class="el-icon-remove operateDel"
          ></i>
          <div v-if="!scope.row.hoverRow">
            <span
              v-if="
                config.pagination &&
                  config.pagination.page &&
                  config.pagination.size
              "
              >{{
                scope.$index +
                  1 +
                  (config.pagination.page - 1) * config.pagination.size
              }}</span
            >
            <span v-else>{{ scope.$index + 1 }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="80" label="序号" v-else>
        <template v-slot="scope">
            <span
              v-if="
                config.pagination &&
                  config.pagination.page &&
                  config.pagination.size
              "
              >{{
                scope.$index +
                  1 +
                  (config.pagination.page - 1) * config.pagination.size
              }}</span
            >
            <span v-else>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <TableColumns
        v-for="(item, i) in config.columns"
        :key="`${item.prop}.${i}`"
        :column="item"
        @handleEvent="handleEvent"
      >
      </TableColumns>
    </el-table>
    <div class="left-view" v-show="!config.hideTotal">
      <template>
        <span>共</span>
        <span class="num" style="color: #333">{{ config.list && config.list.length || 0}}</span>
        <span>条</span>
        <template v-if="config.mutiSelect">
          <span>，已选</span>
          <span class="num">{{ config.check.length }}</span>
          <span>条</span>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
/** 组件使用说明
 * @param {Array} list 列表数据
 * @param {Boolean} loading 是否添加表格loading加载动画
 * @param {Boolean} mutiSelect 是否显示多选框
 * @param {Boolean} reserveSelection 仅对 type=selection 的列有效，类型为 Boolean，为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key）
 */
export default {
  // 组件
  name: 'ComponentTable',
  components: { TableColumns: () => import("@/components/tablePage/tableColumns") },
  model: { prop: 'options', event: 'Device' },
  props: {
    options: { type: Object, required: true }
  },
  // 数据
  data () {
    return {}
  },
  computed: {
    // 是否 显示按钮操作组
    operatesShow () {
      return (
        typeof this.operates === 'object' && this.operates?.list?.length > 0
      )
    }
  },
  computed: {
    config: {
      get () {
        return this.options
      },
      set (val) {
        this.$emit('Device', val)
      }
    }
  },
  methods: {
    // 当选择项发生变化时会触发该事件
    selectionChange (selection) {
      this.config.check = selection
      this.$emit('selection-change', selection)
    },
    click (btn, scope) {
      // space
      if (btn.method) {
        btn.method(scope.row, scope)
      } else {
        this.$emit('handleEvent', btn.click, row, scope)
      }
    },
    rowClick (...selection) {
      // this.$emit('rowClick', ...selection)
      // const listIndex = this.config.list.findIndex(
      //       y => y[this.config.rowKey] === row[this.config.rowKey]
      //     )
      //     if (
      //       this.config.rowClick &&
      //       listIndex >= 0 &&
      //       typeof this.config?.selectable === 'function'
      //         ? this.config.selectable(row, listIndex)
      //         : true
      //     ) {
      //       if (this.config.mutiSelect) {
      //         const index = this.config.check.findIndex(
      //           y => y[this.config.rowKey] === row[this.config.rowKey]
      //         )
      //         if (index >= 0) {
      //           this.config.check.splice(index, 1)
      //         } else {
      //           this.config.check = [...this.config.check, row]
      //         }
      //         this.$refs.table.$refs.mutipleTable.toggleRowSelection(row)
      //       } else {
      //         if (this.radioSelect !== row[this.config.rowKey]) {
      //           this.radioSelect = row[this.config.rowKey]
      //           this.radioSelectObject = row
      //         } else {
      //           this.radioSelect = ''
      //           this.radioSelectObject = null
      //         }
      //         this.config.radio = this.radioSelect
      //         this.config.radioObject = this.radioSelectObject
      //       }
      //     }
    },
    selectAll (...selection) {
      this.$emit('select-all', ...selection)
    },
    select (...selection) {
      this.$emit('select', ...selection)
    },
    getSummaries (param) {
      this.$nextTick(() => {
        this.$refs.mutipleTable.doLayout()
      })
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (this.config.summary.includes(column.property)) {
          const arr = column.property.split('.')
          let values = data.map(item => Number(item[column.property] || 0))

          if (arr?.length === 4) {
            const [p, i, key, s] = arr
            values = data.map(
              item => item[p]?.find?.(x => String(x[key]) === i)?.[s] || 0
            )
          }
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] = `${sums[index].toFixed(2)}`
          } else {
            sums[index] = ''
          }
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    //表格行hover时,显示操作加减号 表格行离开hover时,不显示操作加减号,显示序号
    cellMouseEnter (row) {
      row.hoverRow = true
      this.config.tableCellLabel = '操作'
      const index = this.config.list.indexOf(row)
      this.handleEvent('tableIndex',index)
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave (row) {
      row.hoverRow = false
      this.config.tableCellLabel = '序号'
    },
    //表格增加/减少一行方法
    handleTableRow (name, index) {
      if (name === 'push') {
        this.config.list.splice(index + 1, 0, {})
        this.$emit('handleEvent', 'handleEventPush', index )
      } else {
        if (this.config.list.length <= 1 && this.config.retainColumn) {
          this.$set(this.config.list, index, {})
          this.$emit('handleEvent', 'handleEventDel', index )
          return
        }
        this.config.list.splice(index, 1)
      }
    },
    async handleEvent (type, row) {
      // console.log(row,'row')
      this.$emit('handleEvent', type, row )
    }
  }
}
</script>
<style lang="scss" scoped>
.left-view {
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;

  .num {
    color: #1890ff;
    margin: 0 2px;
  }

  .checkAll {
    margin-left: 5px;
    color: #1890ff;
    cursor: pointer;
  }
}

.operate-group {
  display: flex;
  justify-content: space-around;
}

::v-deep .table-header {
  background-color: #f7f7f7;
}

::v-deep .el-table__body-wrapper {
  background-color: #ffffff;
}
</style>
