<template>
  <!-- 续费产品 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import TablePage from '@/components/tablePage/index.vue'
import { getTenantProductToRenew } from '@/api/tenant/base/info' //所有商户
export default {
  name: 'baseInfoRenewSelect',
  components: { TablePage },
  props: {
    optionsTenant: { type: Object, required: true, default: () => ({}), },
  },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        listName: 'data',
        mutiSelect: true,
        check: [],
        buttons: [
          {
            click: 'add',
            label: '产品续费',
            type: 'primary'
          },
          {
            click: 'getQuit',
            label: '返回',
            type: 'primary',
            right: true
          }
        ],
        defaultBody: {
          productId: this.optionsTenant.productId,
          productBookDetailIds: this.optionsTenant.productBookDetailIds,
          tenantId: this.optionsTenant.tenantId,
        },
        getListApi: getTenantProductToRenew,
        title: '功能产品',
        rowKey: 'productBookDetailId',
        columns: [
          { prop: 'belongProductName', label: '所属产品', minWidth: '200' },
          { prop: 'productNo', label: '产品编号', minWidth: '200' },
          { prop: 'productName', label: '产品名称', width: '200' },
          {
            prop: 'productCalcMode',
            activeText(row) {
              return row.productCalcMode === 1 ? '包年包月' : '数量/次数'
            },
            label: '计费模式',
            minWidth: '200'
          },
          { prop: 'validBegTime', label: '有效期开始', minWidth: '200' },
          { prop: 'validEndTime', label: '有效期结束', minWidth: '160' }
        ],
        list: []
      }
    }
  },
  created() {
    console.log('rou', this.$route)
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'getQuit':
          this.$emit('update:active', 1)
          break
        case 'add':
          if (this.options.check.length === 0)
            return this.$message.error('至少勾选一个产品')
          let obj = {
            type: 1,
            tenantId: this.optionsTenant.tenantId,
            ids: this.options.check
              .map(item => item.productBookDetailId)
              .join(',')
          }
          this.$emit('renewParameters', obj)
          break
      }
    }
  }
}
</script>
