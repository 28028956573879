// 无极远程搜索 + 回显
<template>
  <el-select
    size="mini"
    v-model="selModel"
    v-loading="loading"
    v-bind="$attrs"
    ref="selectTree"
    no-data-text="无可选项"
    :popper-class="`load-tree local-${option.type || ''}`"
    element-loading-text=" 加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255,1)"
    :filterable="option.filterable"
    :filter-method="debounceMethod"
    :reserve-keyword="option.reserveKeyword || true"
    :multiple-limit="option.multipleLimit || 0"
    :clearable="option.clearable || true"
    :value-key="option.valueKey"
    :class="['sys-tree', loading ? 'tree-hide' : '']"
    :disabled="loading || option.disabled ? true : false"
    @visible-change="visibleChange"
  >
    <el-option
      v-if="option && treeData.length"
      value="v@loading@^"
      label="2222"
      style="height: auto"
    >
      <el-tree
        :data="treeData"
        :show-checkbox="option.multiple"
        accordion
        :filter-node-method="filterNode"
        :current-node-key="
          !option.multiple ? (isValuekey ? selModel[keyName] : selModel) : undefined
        "
        :default-expanded-keys="[treeData[0][keyName]]"
        :default-checked-keys="option.multiple ? selModel : undefined"
        :node-key="keyName"
        check-strictly
        ref="tree"
        :indent="12"
        render-after-expand
        :highlight-current="false"
        :props="{
          label: option.label,
          children: option.children || 'children',
          disabled: (data) => data[option.children || 'children'],
        }"
        @check="handleCheckChange"
        @node-click="nodeClick"
        expand-on-click-node
        :default-expand-all="false"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span
            :style="`${isInSelect(data) ? 'color: #409eff; font-weight: 700;' : ''}`"
            >{{ node[option.label] }}</span
          >
          <span>
            <i
              v-if="isInSelect(data)"
              class="el-icon-check"
              style="color: #409eff; font-size: 14px"
            ></i>
          </span>
        </span>
      </el-tree>
    </el-option>
    <template v-if="isArray(extVal)">
      <el-option
        v-show="false"
        v-for="obj in extVal"
        :key="obj[keyName]"
        :label="obj[option.label]"
        :value="isValuekey ? obj : obj[keyName]"
      />
    </template>
  </el-select>
</template>
<script>
import { isArray, deepCopy } from "@/utils";
import { mixin } from "@/components/tablePage/select/mixin/mixin-select.js";
export default {
  name: "WujiSelectRemote",
  mixins: [mixin],
  data() {
    return {
      isArray,
      extVal: "",
      debounce: null,
      treeData: [],
      loading: true, // 加载中
    };
  },
  watch: {
    "option.data"(val) {
      if (!this.option.remote) {
        this.loading = true;
        this.load(val);
      }
    },
    "option.remote": {
      handler() {
        if (typeof this.option.remote === "function") {
          this.loading = true;
          this.loadRequest();
        }
      },
      immediate: true,
    },
    selModel(e) {
      if (this.option.pull) {
        this.loading = true;
        this.load([]);
      }
    },
  },
  computed: {
    // 选中值
    inModel() {
      let data = null;
      if (this.haveModel) {
        data = this.multiple
          ? this.selModel.map((x) => (this.isValuekey ? x[this.keyName] : x))
          : [this.isValuekey ? this.selModel[this.keyName] : this.selModel];
      }
      return data;
    },
  },
  created() {
    if (this.haveModel) {
      this.loadMote();
    } else {
      this.loading = false;
    }
  },
  methods: {
    isInSelect(data) {
      if (!this.multiple) {
        return (
          data[this.keyName] ===
          (this.isValuekey ? this.selModel[this.keyName] : this.selModel)
        );
      } else {
        return this.selModel.find(
          (x) => (this.isValuekey ? x[this.keyName] : x) === data[this.keyName]
        );
      }
    },
    filterNode() {},
    async loadRequest() {
      let data = [];
      try {
        const k = this.option.dataKey || "data";
        data = (await this.option.remote(this.option.remoteBody || {}))[k] || [];
      } catch (error) {
        console.log("loadRequest err-", error);
      }
      this.option.data = data;
      await this.$nextTick();
      this.load(data);
    },
    // 初始化 并且 回显
    async load(val) {
      if (isArray(val)) {
        const extractTree = (data) => {
          if (!data.length) return [];
          const list = [];
          const getObj = (arr) => {
            arr.forEach((row) => {
              let obj = {};
              obj = JSON.parse(JSON.stringify(row));
              list.push(obj);
              if (row.children) {
                getObj(row.children);
              }
            });
            return list;
          };
          return getObj(data);
        };
        this.extVal = extractTree(deepCopy(this.option.data));
        await this.$nextTick();
        this.treeData = deepCopy(this.option.data);
      }
      this.loading = false;
    },
    // 回显
    async loadMote() {
      try {
        this.data = (await this.option.remote()).data;
      } catch (error) {}
    },
    visibleChange(visible) {
      this.isRemote = visible;
      if (visible) {
        if (!this.option.noLoad) {
          this.remoteMethod();
        }
      }
    },
    async handleCheckChange(sel, { checkedNodes = [] } = {}) {
      if (this.multiple) {
        const val = deepCopy(checkedNodes);
        const value = this.isValuekey ? dval : val.map((x) => x[this.keyName]);
        this.extVal = deepCopy(e);
        await this.$nextTick();
        this.selModel = value;
        this.change(value, val);
      }
    },
    async nodeClick(e, { childNodes } = {}) {
      if (!this.multiple && this.option.selectChld ? true : !childNodes.length) {
        const val = deepCopy(e);
        const value = this.isValuekey ? val : val[this.keyName];
        this.extVal = [val];
        await this.$nextTick();
        this.selModel = value;
        this.change(value, val);
        this.$refs.selectTree.blur();
      }
    },
    // 模糊查询搜索
    async remoteMethod(val = "") {},
    change(val, obj) {
      // val -->  v-model 值  /  obj 完整的对象
      if (typeof this.option.change === "function") {
        this.option.change(val, obj);
      }
      this.$emit("selectChange", val, obj);
    },
  },
};
</script>
<style lang="scss" scoped>
.tree-hide {
  ::v-deep .el-select__tags {
    visibility: hidden;
  }
  ::v-deep .el-input__inner {
    color: #f5f7fa;
  }
}
.sys-tree {
  width: 100%;
  ::v-deep .el-loading-mask {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .el-loading-spinner {
      display: flex;
      align-items: center;
      top: 0;
      bottom: 0;
      margin: 0;
      .el-icon-loading,
      .el-loading-text {
        color: #555555;
        margin-left: 10px;
      }
    }
  }
}

.load-tree {
  &.is-multiple {
    padding-right: 20px;

    .static {
      padding-right: 20px;
    }
  }
  .el-select-dropdown__item {
    padding: 0;
  }

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }

  .static {
    opacity: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: rgba($color: #f3f4f5, $alpha: 1);
    border-top: 6px solid #ffffff;
    height: 38px;
    width: 100%;
    padding: 0 20px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      flex: 1;
      font-size: 14px;
      color: #606266;
    }
  }

  .selected {
    .label .headTitle {
      color: #1890ff !important;
    }
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .headTitle {
      flex: 1;
      font-size: 14px;
      color: #606266;
    }
  }
}
</style>
