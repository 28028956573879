var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._b(
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "selectTree",
        class: ["sys-tree", _vm.loading ? "tree-hide" : ""],
        attrs: {
          size: "mini",
          "no-data-text": "无可选项",
          "popper-class": "load-tree local-" + (_vm.option.type || ""),
          "element-loading-text": " 加载中",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255,1)",
          filterable: _vm.option.filterable,
          "filter-method": _vm.debounceMethod,
          "reserve-keyword": _vm.option.reserveKeyword || true,
          "multiple-limit": _vm.option.multipleLimit || 0,
          clearable: _vm.option.clearable || true,
          "value-key": _vm.option.valueKey,
          disabled: _vm.loading || _vm.option.disabled ? true : false,
        },
        on: { "visible-change": _vm.visibleChange },
        model: {
          value: _vm.selModel,
          callback: function ($$v) {
            _vm.selModel = $$v
          },
          expression: "selModel",
        },
      },
      "el-select",
      _vm.$attrs,
      false
    ),
    [
      _vm.option && _vm.treeData.length
        ? _c(
            "el-option",
            {
              staticStyle: { height: "auto" },
              attrs: { value: "v@loading@^", label: "2222" },
            },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.treeData,
                  "show-checkbox": _vm.option.multiple,
                  accordion: "",
                  "filter-node-method": _vm.filterNode,
                  "current-node-key": !_vm.option.multiple
                    ? _vm.isValuekey
                      ? _vm.selModel[_vm.keyName]
                      : _vm.selModel
                    : undefined,
                  "default-expanded-keys": [_vm.treeData[0][_vm.keyName]],
                  "default-checked-keys": _vm.option.multiple
                    ? _vm.selModel
                    : undefined,
                  "node-key": _vm.keyName,
                  "check-strictly": "",
                  indent: 12,
                  "render-after-expand": "",
                  "highlight-current": false,
                  props: {
                    label: _vm.option.label,
                    children: _vm.option.children || "children",
                    disabled: function (data) {
                      return data[_vm.option.children || "children"]
                    },
                  },
                  "expand-on-click-node": "",
                  "default-expand-all": false,
                },
                on: {
                  check: _vm.handleCheckChange,
                  "node-click": _vm.nodeClick,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c("span", { staticClass: "custom-tree-node" }, [
                          _c(
                            "span",
                            {
                              style:
                                "" +
                                (_vm.isInSelect(data)
                                  ? "color: #409eff; font-weight: 700;"
                                  : ""),
                            },
                            [_vm._v(_vm._s(node[_vm.option.label]))]
                          ),
                          _c("span", [
                            _vm.isInSelect(data)
                              ? _c("i", {
                                  staticClass: "el-icon-check",
                                  staticStyle: {
                                    color: "#409eff",
                                    "font-size": "14px",
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  1153822790
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isArray(_vm.extVal)
        ? _vm._l(_vm.extVal, function (obj) {
            return _c("el-option", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              key: obj[_vm.keyName],
              attrs: {
                label: obj[_vm.option.label],
                value: _vm.isValuekey ? obj : obj[_vm.keyName],
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }