var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._b(
      {
        staticClass: "sys-search",
        attrs: {
          multiple: "",
          "reserve-keyword": _vm.option.reserveKeyword || true,
          "multiple-limit": _vm.option.multipleLimit || 0,
          clearable: _vm.option.clearable || true,
        },
        on: { change: _vm.change },
        model: {
          value: _vm.inSelect,
          callback: function ($$v) {
            _vm.inSelect = $$v
          },
          expression: "inSelect",
        },
      },
      "el-select",
      _vm.$attrs,
      false
    ),
    [
      _vm._l(_vm.data, function (item, i) {
        return [
          !item.hide
            ? _c("el-option", {
                key: item.value + item.label,
                attrs: {
                  disabled: i === 0 && _vm.data.length === 1,
                  label: item.label,
                  value: item.value,
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }