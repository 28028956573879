var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "com-page",
      style: { width: _vm.dialogOptions.width + "px" },
    },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "header-top" }, [
          _c("div", { staticClass: "x-bc" }, [
            _c("span", { staticClass: "fontWeight7 fontSize14" }, [
              _vm._v(_vm._s(_vm.option.title)),
            ]),
            _vm.option.dialogOptions &&
            _vm.option.isAddBtn &&
            _vm.treeData.length == 0
              ? _c("div", [
                  _c(
                    "span",
                    {
                      staticClass: "handleEdit",
                      on: { click: _vm.appendTree },
                    },
                    [_vm._v("新增")]
                  ),
                ])
              : _vm.option.dialogOptions && _vm.treeData.length > 0
              ? _c("div", [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isEdit,
                          expression: "isEdit",
                        },
                      ],
                      staticClass: "handleEdit",
                      on: { click: _vm.clickEdit },
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isEdit,
                          expression: "!isEdit",
                        },
                      ],
                      staticClass: "handleEdit",
                      on: { click: _vm.clickEdit },
                    },
                    [_vm._v("完成编辑")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "x-f", staticStyle: { width: "100%" } },
          [
            _c("el-input", {
              staticClass: "flex1",
              attrs: {
                size: "mini",
                placeholder:
                  _vm.option.placeholder || "请输入" + _vm.option.title,
                autocomplete: "off",
                clearable: "",
              },
              on: {
                input: function (val) {
                  return _vm.handleEvent("filterTree", val)
                },
              },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = $$v
                },
                expression: "search",
              },
            }),
            _vm.option.dialogOptions &&
            _vm.option.dialogOptions.api &&
            _vm.option.dialogOptions.api.restore
              ? _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { type: "primary", size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.handleEvent("recycleBin")
                      },
                    },
                  },
                  [_vm._v("回收站")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "tree-view" },
        [
          _vm.show
            ? _c("el-tree", {
                ref: "tableTree",
                attrs: {
                  data: _vm.treeData || [],
                  props: {
                    children: _vm.option.children || "children",
                    label: _vm.option.label || "label",
                  },
                  "node-key": _vm.option.value || "value",
                  "empty-text": _vm.option.emptyText || "暂无数据",
                  "render-after-expand": "",
                  "highlight-current": true,
                  "default-expand-all": _vm.option.defaultExpandAll,
                  "auto-expand-parent": "",
                  "default-expanded-keys": _vm.option.defaultExpandedKeys,
                  "current-node-key": _vm.option.currentNodeKey,
                  "show-checkbox": _vm.option.showCheckbox,
                  "check-strictly": _vm.option.checkStrictly,
                  accordion: "",
                  indent: _vm.option.indent,
                  "filter-node-method": _vm.filterNode,
                  "expand-on-click-node": _vm.option.expandOnClickNode,
                },
                on: {
                  "node-click": function (e) {
                    return _vm.nodeClick(e)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var node = ref.node
                        var data = ref.data
                        return _c("div", { staticClass: "custom-tree-node" }, [
                          _c("div", { staticClass: "omit1 flex1" }, [
                            _vm._v(_vm._s(node.label)),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.isEdit,
                                  expression: "!isEdit",
                                },
                              ],
                              staticStyle: { width: "70px" },
                            },
                            [
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: data.label != "未分类",
                                    expression: "data.label != '未分类'",
                                  },
                                ],
                                staticClass: "iconBtnTree",
                                attrs: {
                                  icon: "el-icon-circle-plus-outline",
                                  type: "text",
                                  size: "mini",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (function () {
                                      return _vm.appendTree(node, data)
                                    })($event)
                                  },
                                },
                              }),
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      data.id != 0 &&
                                      data.id != -1 &&
                                      data.label != "未分类",
                                    expression:
                                      "data.id != 0 && data.id != -1 && data.label != '未分类'",
                                  },
                                ],
                                staticClass: "iconBtnTree",
                                attrs: {
                                  icon: "el-icon-edit",
                                  type: "text",
                                  size: "mini",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (function () {
                                      return _vm.editTree(node, data)
                                    })($event)
                                  },
                                },
                              }),
                              _c("el-button", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      data.id != 0 &&
                                      data.id != -1 &&
                                      data.label != "未分类",
                                    expression:
                                      "data.id != 0 && data.id != -1 && data.label != '未分类'",
                                  },
                                ],
                                staticClass: "iconBtnTree",
                                attrs: {
                                  icon: "el-icon-delete",
                                  type: "text",
                                  size: "mini",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return (function () {
                                      return _vm.removeTree(node, data)
                                    })($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      },
                    },
                  ],
                  null,
                  false,
                  2355995836
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("Dialog", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }