<template>
  <el-table-column
    :label="column.label"
    :width="column.width"
    :min-width="column.minWidth"
    :prop="column.prop"
    :fixed="column.fixed"
    :align="column.align || 'center'"
    :show-overflow-tooltip="
      !['html', 'slot2', 'slots', 'tag'].includes(column.type) && !column.hideTooltip
    "
    :sortable="column.sortable"
  >
    <template v-if="column.children">
      <el-table-column
        v-for="item in getChildren(column.children)"
        :key="`${column.prop}.${item.prop}`"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
        :min-width="item.minWidth"
        :align="item.align || 'center'"
      >
        <template #default="{ row }">{{ getValue(row, item) }}</template>
      </el-table-column>
    </template>
    <template slot="header" v-if="!column.children && column.rules">
      <i style="color: #ff4949">*</i>
      {{ column.label }}
    </template>
    <template slot="header" v-if="!column.children && column.tooltip">
      <div class="header-container">
        {{ column.label }}
        <el-tooltip
          class="tooltip"
          effect="dark"
          :content="column.tooltip"
          placement="top"
        >
          <i class="el-icon-question" style="font-size: 14px"></i>
        </el-tooltip>
      </div>
    </template>

    <slot v-if="column.type === 'slot2'" />

    <template v-if="!column.children" slot-scope="{ row, $index, column: columnObj }">
      <slot :name="column.prop" v-if="column.type === 'slots'" />
      <template v-else>
        <slot
          :name="'slot-' + column.prop"
          v-if="column.type === 'slot2'"
          :row="row"
          :$index="$index"
          :column="columnObj"
        />
        <!-- 链接内容 -->
        <el-link
          v-else-if="column.link || column.type === 'link'"
          type="primary"
          class="sys-link-a"
          @click.stop="onClick(column.click, row)"
          >{{
            column.formatter
              ? column.formatter(row[column.prop], row, columnObj)
              : row[column.prop]
          }}</el-link
        >
        <!-- 富文本内容 -->
        <el-popover placement="top" trigger="hover" v-else-if="column.type === 'html'">
          <template>
            <div v-if="column.html" class="set-popper" v-html="column.html(row)"></div>
            <div v-else class="set-popper" v-html="row[column.prop]"></div>
          </template>
          <div slot="reference" class="set-content" v-html="row[column.prop]"></div>
        </el-popover>

        <!-- 字典 -->
        <template v-else-if="column.type === 'dict'">
          {{
            column.formatter
              ? column.formatter(row[column.prop], row, columnObj)
              : getDictLabel(column, row).label
          }}
        </template>

        <!-- 字典 hezhi -->
        <template v-else-if="column.type === 'dicts'">
          <p v-if="!column.tag">{{ getDictsLabel(column, row) }}</p>
          <el-tag v-else :type="column.tag">{{ getDictsLabel(column, row) }}</el-tag>
        </template>

        <!-- 类型为image -->
        <template v-else-if="column.type === 'image'">
          <img
            v-if="row[column.prop]"
            style="width: 50px; object-fit: cover; height: 50px"
            @click.stop="showBigImage(row[column.prop])"
            :src="row[column.prop]"
            alt=""
          />
          <div
            v-else
            style="width: 50px; height: 50px; border: 1px solid #eeeeee; margin: auto"
          ></div>
        </template>

        <!-- 标签内容 -->
        <div v-else-if="column.type === 'tag'">
          <div v-if="row[column.tagKey]">
            <el-tag
              style="margin: 3px"
              v-for="(tag, i) in row[column.tagKey]"
              :key="tag + i"
              >{{ column.tagLabel && tag ? tag[column.tagLabel] : tag || "" }}</el-tag
            >
          </div>

          <el-tag v-else-if="column.tagDefault" style="margin: 3px">{{
            column.tagDefault || ""
          }}</el-tag>
        </div>
        <!-- 按钮/按钮组 -->
        <div v-else-if="column.type === 'button'">
          <el-button
            :size="item.size || 'mini'"
            :type="item.type || 'text'"
            :plain="item.plain"
            :round="item.round"
            :circle="item.circle"
            :loading="item.loading"
            :disabled="item.disabled"
            :icon="item.icon"
            v-for="item in column.button"
            :key="item.click"
            @click.stop="onClick(item.click, row)"
            >{{ item.text }}
          </el-button>
        </div>
        <!-- 图标内容 -->
        <div v-else-if="column.type === 'icons'">
          <i
            :class="
              column.formatter
                ? column.formatter(row[column.prop], row, columnObj).icon
                : column.elIcon
            "
            :style="
              column.formatter
                ? column.formatter(row[column.prop], row, columnObj).style
                : column.elStyle
            "
          />
        </div>
        <!-- 时间选择 -->
        <div v-else-if="column.type === 'selectTime'">
          <el-date-picker
            v-model="row[column.prop]"
            type="date"
            size="mini"
            value-format="yyyy-MM-dd"
            style="width: 140px"
            :disabled="column.disabled"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>

        <!-- 动态内容 -->
        <template v-else-if="column.activeText">
          {{ column.activeText(row) }}
        </template>

        <!-- radio -->
        <div v-else-if="column.type === 'switch'" @click.stop>
          <el-switch
            v-model="row[column.prop]"
            :active-value="column.active"
            :inactive-value="column.inactive"
            :active-text="column.activeLabel"
            :inactive-text="column.inactiveLabel"
            :disabled="
              typeof column.disabled === 'function'
                ? column.disabled(row[column.prop], row)
                : column.disabled
            "
            @click.stop="true"
            @change="
              typeof column.onClick === 'function'
                ? column.onClick(column.type, row)
                : onClick(column.type, row)
            "
          />
        </div>

        <SelectLocal
          v-else-if="column.type === 'my-select-local'"
          v-model="row[column.prop]"
          :option="
            typeof column.option === 'function' ? column.option(row) : column.option
          "
          :filterable="column.option.filterable"
          :multiple="column.option.multiple"
          :disabled="column.disabled"
          :clearable="column.option.clearable"
          :collapse-tags="column.option['collapse-tags']"
          :placeholder="column.placeholder || `选择${column.label}`"
          @selectChange="
            () =>
              typeof column.rowChange === 'function' ? column.rowChange(row) : undefined
          "
        />
        <template v-else-if="column.type === 'my-checkbox'">
          <el-checkbox
            v-if="!row.children || !row.children.length"
            :true-label="column.trueLabel || 1"
            :false-label="column.falseLabel || 0"
            v-model="row[column.prop]"
          />
        </template>
        <el-input
          :type="column.inputType ? column.inputType : 'text'"
          :disabled="
            typeof column.disabled === 'function'
              ? column.disabled(row[column.prop], row)
              : column.disabled
          "
          v-else-if="column.type === 'my-input'"
          v-model="row[column.prop]"
          size="mini"
        />
        <!-- 输入框 -->
        <el-form-item
          v-else-if="column.type == 'input'"
          :prop="column.rules ? 'list.' + $index + `.${column.prop}` : ''"
          :rules="{
            required: true,
            message: `${column.label}不能为空`,
            trigger: ['change', 'blur'],
          }"
        >
          <el-input
            v-model="row[column.prop]"
            size="mini"
            @input="
              onInput(
                column.input,
                row,
                $index,
                column.prop,
                column.isMinus,
                column.inputNum,
                column.notVerify
              )
            "
            @blur="onBlur(column.blurInput, row, $index)"
            :disabled="column.disabled"
          />
        </el-form-item>

        <!-- 点击输入框 -->
        <el-form-item
          v-else-if="column.type == 'clickInput'"
          :prop="column.rules ? 'list.' + $index + `.${column.prop}` : ''"
          :rules="{
            required: true,
            message: `${column.label}不能为空`,
            trigger: ['blur', 'change'],
          }"
        >
          <el-input v-model="row[column.prop]" size="mini" :disabled="column.disabled">
            <i
              slot="suffix"
              class="el-icon-more pointer"
              @click.stop="onClick(column.click, row, $index, column.prop)"
            ></i>
          </el-input>
        </el-form-item>
        <!-- 本地数据选择器 -->
        <el-form-item
          v-else-if="column.type === 'localSelect'"
          :prop="column.rules ? 'list.' + $index + `.${column.prop}` : ''"
          :rules="{
            required: true,
            message: `${column.label}不能为空`,
            trigger: ['blur', 'change'],
          }"
        >
          <SelectLocal
            v-model="row[column.prop]"
            :option="computedOptionLocalSelect(column, $index, row)"
            :rowDataKey="column.rowDataKey"
            :row="row"
            :filterable="column.option.filterable || false"
            :multiple="column.option.multiple || false"
            :disabled="
              typeof column.disabled === 'function'
                ? column.disabled(row[column.prop], row)
                : column.disabled
            "
            :clearable="column.option.clearable || false"
            :collapse-tags="column.option['collapse-tags']"
            :placeholder="column.placeholder || `选择${column.label}`"
          />
        </el-form-item>
        <!-- 远程数据选择器 -->
        <el-form-item
          v-else-if="column.type === 'remoteSelect'"
          :prop="column.rules ? 'list.' + $index + `.${column.prop}` : ''"
          :rules="{
            required: true,
            message: `${column.label}不能为空`,
            trigger: ['blur', 'change'],
          }"
        >
          <SelectRemote
            v-model="row[column.prop]"
            :option="computedOptionRemoteSelect(column, $index, row)"
            :filterable="column.option.filterable || false"
            :multiple="column.option.multiple || false"
            :clearable="column.option.clearable || false"
            :collapse-tags="column.option['collapse-tags']"
            :placeholder="column.placeholder || `选择${column.label}`"
            @handleEvent="
              (type, option) => {
                $emit('handleEvent', type, option);
              }
            "
            :disabled="
              typeof column.disabled === 'function'
                ? column.disabled(row[column.prop], row)
                : column.disabled
            "
          />
        </el-form-item>
        <!-- 默认 -->
        <template v-else>
          <span
            :style="column.style ? column.style(row[column.prop], row, columnObj) : ''"
            >{{
              column.formatter
                ? column.formatter(row[column.prop], row, columnObj)
                : row[column.prop]
            }}</span
          >
        </template>
      </template>
    </template>

    <el-dialog :visible.sync="dialogImageVisible" :append-to-body="true">
      <!-- 不为数组时则展示一张 -->
      <img class="image" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </el-table-column>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import { deepCopy, limitInputlength } from "@/utils";

export default {
  name: "TabelPageTableColumns",
  components: { SelectRemote, SelectLocal },
  props: {
    column: Object, // 详情见 element table option
  },
  data() {
    return {
      dialogImageVisible: false,
      dialogImageUrl: "",
    };
  },
  computed: {
    dicts() {
      let dicts = null;
      for (let index = 0; index < 14; index++) {
        dicts = dicts?.$parent || this;
        if (dicts?.$children?.find?.((x) => x.isTablePageCom) && dicts?.dict?.type) {
          dicts = dicts?.dict?.type;
          break;
        } else if (index === 13) {
          dicts = null;
        }
      }
      return dicts || {};
    },
    computedOptionLocalSelect() {
      return (column, index, row) => {
        let option = deepCopy(column.option);
        if (column.showItem) {
          option.showItem = [
            {
              [column.showItem.optionId]: row[column.showItem.prop],
              [column.showItem.optionName]: row[column.showItem.label],
            },
          ];
        }
        return option;
      };
    },
    computedOptionRemoteSelect() {
      return (column, index, row) => {
        let option = deepCopy({
          ...column.option,
          curTableIndex: index,
          disabled:
            typeof column.disabled === "function"
              ? column.disabled(row[column.prop], row)
              : column.disabled,
        });
        if (column.showItem) {
          option.showItem = [
            {
              [column.showItem.optionId]: row[column.showItem.prop],
              [column.showItem.optionName]: row[column.showItem.label],
            },
          ];
        }
        return option;
      };
    },
  },
  methods: {
    getChildren(children) {
      const newChildren = typeof children === "function" ? children() : children;
      console.log("newChildrennewChildren", newChildren);
      return newChildren?.length ? newChildren : [{}];
    },
    getValue(row, item, prop = "") {
      if (typeof item.getValue === "function") {
        return item.getValue(row, item.prop);
      } else if (typeof item.formatter === "function") {
        return item.formatter(row[item.prop], row);
      } else {
        return row[item.prop];
      }
    },
    //保留小数位
    limitInputlength,
    /** 图片放大处理 */
    showBigImage(url) {
      this.dialogImageUrl = url;
      this.dialogImageVisible = true;
    },

    // 层级往下，寻找字典数组
    getDictsList(name) {
      return (
        this.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.$parent?.$parent?.dict?.type[name] ||
        this.$parent?.$parent?.$parent?.$parent?.$parent?.$parent?.$parent?.dict?.type[
          name
        ] ||
        {}
      );
    },

    // 查询字典值
    getDictsLabel({ name, prop }, row) {
      return this.selectDictLabel(this.getDictsList(name), row[prop]);
    },

    getDictLabel({ dict, prop, name } = {}, row) {
      const item =
        this.dicts?.[dict]?.find?.((x) => String(x.value) === String(row[prop])) || {};
      return item;
    },

    // 点击事件
    onClick(click, row = null, index, prop) {
      if (row.disabled) return;
      row.index = index;
      row.prop = prop;
      this.$emit("handleEvent", click, row);
    },
    //输入事件
    onInput(input, row = null, index, prop, isMinus, inputNum = 8, notVerify) {
      console.log(isMinus, "isMinus");
      // row[prop] = this.limitInputlength(row[prop], row, prop, true, 8, isMinus); //限制小数位
      if (!notVerify)
        row[prop] = this.limitInputlength(row[prop], row, prop, true, inputNum, isMinus); //限制小数位
      row.index = index;
      row.prop = prop;
      this.$emit("handleEvent", input, row);
    },
    //失去焦点事件
    onBlur(blurInput, row = null, index) {
      this.$emit("handleEvent", blurInput, { ...row, index });
    },
  },
};
</script>
<style lang="scss" scoped>
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.sys-link-a {
  &:hover {
    text-decoration: underline;
    &:after {
      display: none;
    }
  }
}

p {
  margin: 0 !important;
}

.set-popper {
  width: 331px;
  max-height: 240px;
  overflow: hidden;
  overflow-y: auto;
}
.set-content {
  cursor: default;
  height: 60px;
  overflow-y: auto;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.el-form-item {
  margin-bottom: 0 !important;
}

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
::v-deep .el-form-item__error {
  position: static;
}

.image {
  width: 30vh;
}
</style>
