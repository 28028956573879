import { haveContent } from '@/utils'
export const mixin = {
  model: { prop: 'value', event: 'Device' },
  props: {
    row: {
      type: Object,
      default: () => ({})
    },
    option: Object,
    value: [Object, Number, String, Array]
  },
  data() {
    return {}
  },
  computed: {
    // el-selcet 选择器绑定值
    selModel: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('Device', val)
      }
    },
    // 绑定子是否为对象
    isValuekey() {
      return Boolean(this.option.valueKey)
    },
    // model 是否有值
    haveModel() {
      return Boolean(
        (this.multiple && this.selModel?.length > 0) ||
        (!this.multiple && haveContent(this.selModel))
      )
    },
    // 选择器绑定的键名
    keyName() {
      return this.option.valueKey ? this.option.valueKey : this.option.value
    },
    // 是否多选 :multiple="true" 开启多选
    multiple() {
      return Boolean(this.$attrs.multiple)
    },
  },
  methods: {
    // 获取整行数据
    getRadioData(v) {
      let obj = null;
      if (haveContent(v)) {
        if (this.isValuekey) {
          obj = v
        } else if (this.multiple) {
          obj = this.data.filter(x => v.includes(x[this.keyName]));
          if (!obj?.length || v.length !== obj.length) {
            obj = null
          }
        } else {
          obj = this.data?.find?.(x => x[this.keyName] == v)
        }
      }
      return obj
    },
    // 输入防抖
    debounceMethod(val = '') {
      const clear = () => {
        try {
          clearTimeout(this.debounce)
          this.debounce = null
        } catch (error) { }
      }
      if (this.debounce) {
        clear()
        if (val === '') {
          return this.remoteMethod(val)
        }
      }
      this.debounce = setTimeout(() => {
        clear()
        this.remoteMethod(val)
      }, this.option?.debounce || 200)
    },
    // 输入防抖通用
    debounceFnc(key = 'debounce', fnc = () => { }, debounce = 200) {
      const clear = () => {
        try {
          clearTimeout(this[key])
          this[key] = null
        } catch (error) { }
      }
      clear()
      this[key] = setTimeout(() => {
        clear()
        fnc()
      }, debounce)
    },
  }
}
