<template>
  <div>
    <el-input
      v-if="modelValue"
      v-model="modelValue.name"
      v-bind="$attrs"
      placeholder="选择链接"
      class="miniAppUrl"
      resize="none"
      prefixIcon="el-icon-link"
      readonly
      style="width: 100%"
      @focus="getOpenLink({ type: 1 })"
    >
      <template v-if="modelValue.name" #append>
        <div style="padding: 0 10px" @click.stop="getOpenLink({ type: 1, noRule: true })">
          修改
        </div>
      </template>
    </el-input>
    <SelectLink :OpenLink.sync="openLink" @selectLinkObj="selectLinkObj" />
  </div>
</template>
<script>
import SelectLink from "@/views/components/selectLink/index.vue"; //链接库

export default {
  name: "minapp-url",
  components: { SelectLink },
  model: {
    prop: "value",
    event: "modelValue",
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("modelValue", e);
      },
    },
  },
  data() {
    return {
      openLink: false,
      openLinkType: {},
    };
  },
  methods: {
    change(e) {
      this.viewIndex = e;
    },
    selectLinkObj(e) {
      const { type } = this.openLinkType;
      switch (type) {
        case 1:
          this.modelValue = e;
          break;
        default:
          break;
      }
    },
    getOpenLink(e = {}) {
      const { type, noRule } = e;
      switch (type) {
        case 1:
          if (this.modelValue.name && !noRule) {
            return false;
          }
          break;
        default:
          break;
      }
      this.openLinkType = e;
      this.openLink = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.miniAppUrl {
  ::v-deep .el-input-group__append {
    padding: 0;
    color: #1890ff !important;
    cursor: pointer;
    &:hover {
      color: #2888e0 !important;
      background-color: #f7f8f9;
    }
  }
}
</style>
