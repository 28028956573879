<template>
  <el-dialog
    class="x-c center-dialog"
    title="文件导入"
    :visible.sync="visible"
    width="500px"
    v-dialog-drag
    :before-close="close"
  >
    <div>
      <div class="boxRow1 x-bc marB10">
        <el-button size="mini" icon="el-icon-upload2" @click="handleUpload"
          >上传文件</el-button
        >
        <div class="fontS14">
          引入文件必须符合模板格式，请下载
          <!-- <a :href="downloadFile.downloadUrl" rel="external nofollow" :download="downloadFile.fileName"
                    >默认模板</a> -->
          <el-button
            type="text"
            @click="downloadFileFn(downloadFile.downloadUrl)"
            class="downloadBtn"
          >
            默认模板
          </el-button>
          <!--                    <a class="marL10">引入填写示例</a>-->
        </div>
      </div>
      <el-upload
        class="upload-resource"
        drag
        :action="uploadFile"
        :on-success="uploadSuccess"
        :on-remove="removeFile"
        :http-request="uploadRequest"
        :on-change="handleChange"
        multiple
        ref="upload"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <span slot="footer" class="x-f-end">
      <el-button size="mini" @click="close" class="marL10">取 消</el-button>
      <el-button size="mini" type="primary" class="marL10" @click="confirm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { delOss } from '@/api/ossfile'
export default {
  name: 'index', // 导入组件
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 下载模板的url
    downloadFile: {
      type: Object,
      default: {
        fileName: '', // 文件名
        downloadUrl: '' // 下载地址
        // request: true
      }
    }
    // //上传地址
    // uploadUrl: {
    //   type: String,
    //   require: true,
    //   default: ''
    // }
  },
  watch: {
    visible (newVal) {
      if (!newVal) {
        this.$refs.upload.clearFiles()
      }
    }
  },
  data () {
    return {
      uploadFile: process.env.VUE_APP_BASE_API + this.uploadUrl,
      formData: undefined
    }
  },
  methods: {
    handleChange () {},
    downloadFileFn (url) {
      console.log('下载', url)
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.download = '隼云导入示范文件.xlsx' // 设置下载的文件名
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // 上传成功
    uploadSuccess (file, fileList) {
      console.log('上传：succes', file, fileList)
      this.$emit('getFileUrl')
    },
    // 自定已上传
    uploadRequest (params) {
      console.log('上传：succes', params)

      const file = params.file
      // 使用FormData传参数和文件
      this.formData = new FormData()
      // 添加键值对
      this.formData.append('file', file)
      console.log('formData = ', this.formData)
      // 传给后台FormData， 此时form = {date: xx, file: xxx(FormData)}
    },
    removeFile (file, fileList) {
      console.log('删除', file)
      let url = file.response.data.accessUrl
      if (url) {
        delOss(url).then(res => {
          this.$emit('removeFile', url)
          this.$message.success('删除文件成功')
        })
      }
    },
    // 模拟点击
    handleUpload () {
      document.querySelector('.upload-resource .el-upload').click()
    },
    // handleClose (done) {
    //     this.$confirm('确认关闭？','提示', {
    //         confirmButtonText: '确定',
    //         cancelButtonText: '取消',
    //         type: 'warning'
    //     })
    //         .then(_ => {
    //             if (!this.levelType) {
    //                 this.$emit('update:showDialog', false)
    //             } else {
    //                 this.$emit('close', false)
    //             }
    //         })
    //         .catch(_ => {})
    // },
    close () {
      this.$emit('update:visible', false)
    },
    confirm () {
      this.$emit('upload', this.formData)
      // this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.boxRow1 {
  width: 100%;

  a {
    color: #1890ff;
  }
}

::v-deep .el-icon-upload:before {
  content: '+';
}

::v-deep .el-upload-dragger .el-icon-upload {
  font-size: 57px;
}

::v-deep .el-upload {
  width: 100%;
}

::v-deep .el-upload-dragger {
  width: 100%;
  height: 200px;
  background-color: #f1f1f1;
}

.center-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-dialog .el-dialog {
  margin: 0 auto;
}
</style>
