
<template>
  <el-date-picker
    append-to-body
    ref="datePicker"
    unlink-panels
    @focus="formDataTime1"
    :picker-options="pickerOptionsWeek"
    popper-class="datePicker"
    v-model="date"
    format="MM 月 dd 日"
    value-format="MM-dd"
    type="daterange"
    range-separator="至"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
  />
</template>
  
<script>
export default {
  name: "MDrange", // 本年内 月日 选择器
  props: {
    value: { required: true }
    // 是否禁用
  },
  model: { prop: "value", event: "Device" },
  data() {
    return {
      pickerOptionsWeek: {
        disabledDate(time) {
          return time.getFullYear() !== new Date().getFullYear(); // 判断不是当前年份即禁用
        }
      }
    };
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("Device", val);
      }
    }
  },
  methods: {
    /** 格式化面板标题 */
    watchDate() {
      // 判断datePicker是否存在
      try {
        if (document.getElementsByClassName("datePicker")[0]) {
          // 通过类型找到日期选择的面板元素

          let divHtml = document
            .getElementsByClassName("datePicker")[0]
            .getElementsByClassName("el-picker-panel__body-wrapper")[0]
            .lastChild.getElementsByClassName("el-picker-panel__content");
          // 获取标题元素
          let divContent1 = divHtml[0].getElementsByClassName(
            "el-date-range-picker__header"
          )[0];
          let divContent2 = divHtml[1].getElementsByClassName(
            "el-date-range-picker__header"
          )[0];
          // 对元素进行判断并重新赋值

          let text1 = divContent1.lastChild.innerHTML;
          let text2 = divContent2.lastChild.innerHTML;
          // el-icon-arrow-left
          if (text1.indexOf("年") != -1) {
            divContent1.lastChild.setAttribute("style", "display: none");
            divContent1.lastChild.setAttribute("class", "icon-right");

            const div = document.createElement("div");
            div.innerHTML = text1.split("年")[1];
            divContent1.appendChild(div);
          } else {
            text1 = divContent1.getElementsByClassName("icon-right")[0]
              .innerHTML;
            divContent1.lastChild.innerHTML = text1.split("年")[1];
          }
          const button1 = divContent1.getElementsByClassName(
            "el-picker-panel__icon-btn el-icon-arrow-left"
          )[0];
          if (text1.indexOf(" 1 ") >= 0) {
            button1.setAttribute("disabled", "disabled");
            button1.setAttribute("style", "cursor:not-allowed");
          } else {
            button1.removeAttribute("disabled");
            button1.removeAttribute("style");
          }
          if (text2.indexOf("年") != -1) {
            divContent2.lastChild.setAttribute("style", "display: none");
            divContent2.lastChild.setAttribute("class", "icon-right");

            const div = document.createElement("div");
            div.innerHTML = text2.split("年")[1];
            divContent2.appendChild(div);
          } else {
            text2 = divContent2.getElementsByClassName("icon-right")[0]
              .innerHTML;
            divContent2.lastChild.innerHTML = text2.split("年")[1];
          }
          const button2 = divContent2.getElementsByClassName(
            "el-picker-panel__icon-btn el-icon-arrow-right"
          )[0];
          if (text2.indexOf(" 12 ") >= 0) {
            button2.setAttribute("disabled", "disabled");
            button2.setAttribute("style", "cursor:not-allowed");
          } else {
            button2.removeAttribute("disabled");
            button2.removeAttribute("style");
          }
        }
      } catch (error) {
        console.log("eeeeeeeee", error);
      }
    },
    /** 监听datepicker获取焦点 */
    formDataTime1(e) {
      this.$nextTick(() => {
        this.watchDate(); // 格式化面板标题
        // 通过循环为月份切换按钮添加监听事件
        document
          .querySelectorAll(
            ".el-picker-panel__icon-btn.el-icon-arrow-left,.el-picker-panel__icon-btn.el-icon-arrow-right"
          )
          .forEach(item =>
            item.addEventListener("click", () => {
              this.$nextTick(() => {
                this.watchDate(); // 格式化面板标题
              });
            })
          );
      });
    }
  }
};
</script>
<style lang="scss" >
.datePicker {
  .el-icon-d-arrow-left {
    display: none;
  }
  .el-icon-d-arrow-right {
    display: none;
  }

  /** 伪类 */
  ::v-deep .icon-left::before {
    content: attr(data-attr);
    background-color: white;
    position: absolute;
    top: 0;
    left: 3rem;
    width: 12.5rem;
  }
  ::v-deep .icon-right::before {
    content: attr(data-attr);
    background-color: white;
    position: absolute;
    top: 0;
    left: 3rem;
    width: 12.5rem;
  }
}
</style>

<style lang="scss" scoped>
</style>
  