<template>
  <el-radio-group v-model="tiemVal" @change="change" v-bind="$attrs">
    <el-radio-button :label="radio.label" v-for="(radio, i) in groupData" :key="i">
      {{ radio.text }}
    </el-radio-button>
  </el-radio-group>
</template>

<script>
import { getTimes } from "@/utils/index.js";
export default {
  name: "timeRange", // 本年内 月日 选择器
  props: {
    // 默认调用 change 事件
    defaultChange: {
      type: Boolean,
      default: false,
    },
    // 默认日期
    defaultValue: {
      type: [String, Array, Object],
    },
    // 跟着日期进行联动
    linkage: {
      type: Boolean,
      default: true,
    },
    // 格式化样式
    defaultFormat: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
    },
    data: {
      type: Array,
      default: () => [],
    },
    value: { required: true },
  },
  model: { prop: "value", event: "Device" },
  watch: {
    value: {
      handler(e) {
        if (Array.isArray(e)) {
          const item = this.groupData.find(
            (x) => x.date[0] === e[0] && x.date[1] === e[1]
          );
          if (item) {
            this.tiemVal = item.label;
            return;
          }
        }
        if (this.value === this.defaultValue && this.defaultValue) {
          this.tiemVal = this.defaultValue;
          this.change(this.tiemVal, this.defaultChange);
          return;
        }
        this.tiemVal = "";
        this.timeVals = [];
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      timeRadios: {
        latelyThreeAndDay: "最近三天",
        nextThreeAndDay: "最近三天",

        BAThreedays: "近七天",
        tomorrow: "明天",

        latelySevenDay: "最近七天",
        day: "今天",
        week: "本周",
        yesterday: "昨天",
        month: "本月",
        year: "本年",
        quarter: "本季",
        lastWeek: "上周",
        lastMonth: "上月",
        lastQuarter: "上季",
      },
      // 今天 昨天 本周 上周 本月上月本季上季本年
      timeVals: [],
      tiemVal: "",
      groupData: [],
    };
  },
  mounted() {
    this.groupData = this.data.map((x) => ({
      label: x,
      text: this.timeRadios[x],
      date: this.getTimes(x),
    }));
    this.$nextTick(() => {
      if (this.defaultValue) {
        this.tiemVal = this.defaultValue;
        this.change(this.tiemVal, this.defaultChange);
      }
    });
  },
  methods: {
    getTimes(type) {
      let start = "";
      let end = "";
      const formats = {
        daterange: "YYYY-MM-DD",
        daterangeStart: "YYYY-MM-DD",
        daterangeEnd: "YYYY-MM-DD",
        datetimerange: "YYYY-MM-DD hh:mm:ss",
        datetimerangeStart: "YYYY-MM-DD 00:00:00",
        datetimerangeEnd: "YYYY-MM-DD 23:59:59",
      };
      return getTimes({
        type,
        format: this.format,
        HMSRang: !this.defaultFormat,
      });
    },
    change(e, change = true) {
      const { date = "" } = this.groupData.find((x) => x.label === e) || {};
      if (
        Array.isArray(this.value) && Array.isArray(date)
          ? this.value[0] !== date[0] || this.value[1] !== date[1]
          : true
      ) {
        this.timeVals = date;
        this.$emit("Device", this.timeVals);
        if (change) {
          this.$emit("change", this.timeVals);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
