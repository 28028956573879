el-button<template>
  <div class="com-page" :style="{width:dialogOptions.width+'px'}">
    <div class="header">
      <div class="header-top">
        <div class="x-bc">
          <span class="fontWeight7 fontSize14">{{ option.title }}</span>
          <div v-if="option.dialogOptions&&option.isAddBtn&&treeData.length==0">
            <span class="handleEdit" @click="appendTree">新增</span>
          </div>
          <div v-else-if="option.dialogOptions&&treeData.length > 0">
            <span class="handleEdit" v-show="isEdit" @click="clickEdit">编辑</span>
            <span class="handleEdit" v-show="!isEdit" @click="clickEdit">完成编辑</span>
          </div>
        </div>
      </div>
      <div style="width: 100%" class="x-f">
        <el-input class="flex1" size="mini" v-model="search" :placeholder="option.placeholder || `请输入${option.title}`"
          autocomplete="off" clearable @input="val => handleEvent('filterTree', val)" />
        <el-button v-if="option.dialogOptions&&option.dialogOptions.api&&option.dialogOptions.api.restore"
          class="marL10" type="primary" size="mini" @click="handleEvent('recycleBin')">回收站</el-button>
      </div>
    </div>
    <div class="tree-view">
      <el-tree v-if="show" ref="tableTree" :data="treeData || []" :props="{
          children: option.children || 'children',
          label: option.label || 'label'
        }" :node-key="option.value || 'value'" :empty-text="option.emptyText || '暂无数据'" render-after-expand
        :highlight-current="true" :default-expand-all="option.defaultExpandAll" auto-expand-parent
        :default-expanded-keys="option.defaultExpandedKeys" :current-node-key="option.currentNodeKey"
        :show-checkbox="option.showCheckbox" :check-strictly="option.checkStrictly" accordion :indent="option.indent"
        :filter-node-method="filterNode" @node-click="e => nodeClick(e)"
        :expand-on-click-node='option.expandOnClickNode'>
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div class="omit1 flex1">{{ node.label }}</div>
          <div style="width:70px" v-show="!isEdit">
            <el-button v-show="data.label != '未分类'" class="iconBtnTree" icon="el-icon-circle-plus-outline" type="text"
              size="mini" @click.native.stop="() => appendTree(node, data)">
            </el-button>
            <el-button v-show="data.id != 0 && data.id != -1 && data.label != '未分类'" class="iconBtnTree"
              icon="el-icon-edit" type="text" size="mini" @click.native.stop="() => editTree(node, data)">
            </el-button>
            <el-button v-show="data.id != 0 && data.id != -1 && data.label != '未分类'" class="iconBtnTree"
              icon="el-icon-delete" type="text" size="mini" @click.native.stop="() => removeTree(node, data)">
            </el-button>
          </div>
        </div>
      </el-tree>
    </div>
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
export default {
  name: 'table-tree',
  props: {
    option: {
      type: Object,
      default: () => ({}),
      required: true
    },
    modelValue: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  components: {
    Dialog: () => import('@/components/Dialog')
  },
  model: { prop: 'modelValue', event: 'modelValue' },
  data() {
    return {
      dialogOptions: {
        title: '',
        width: 620,
        show: false,
        formData: null
      },
      show: true,
      treeData: [],
      search: '',
      isEdit: true, //编辑
      onRow: null // 设置树形选中
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('modelValue', val)
      }
    }
  },
  methods: {
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: '提交中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)',
        customClass: 'topLoading'
      })
    },
    closeLoading() {
      try {
        this.loading.close()
      } catch (error) { }
    },
    //新增Tree用户组
    appendTree(node, data) {
      const { title, api, ...otherOpt } = this.option?.dialogOptions

      const options = {
        ...otherOpt,
        api,
        show: true,
        title: title ? `新增${title}` : '',
        click: 'add',
        formData: {}
      }
      this.onRow = data
      this.dialogOptions = { ...this.dialogOptions, ...options }
    },
    //编辑Tree用户组
    async editTree(node, data) {
      let formData = data
      const { title, api, ...otherOpt } = this.option?.dialogOptions
      if (typeof api.detail === 'function') {
        try {
          this.showLoading()
          let id = data.id ? 'id' : this.option.value
          const res = await api.detail(data[id])
          formData = res.data || {}
        } catch (error) { }
        this.closeLoading()
      }
      const options = {
        ...otherOpt,
        api,
        show: true,
        title: title ? `编辑${title}` : '',
        click: 'update',
        formData
      }
      this.dialogOptions = { ...this.dialogOptions, ...options }
    },
    //删除Tree用户组
    async removeTree(node, data) {
      // this.$emit('removeTree', { node, data })
      this.$modal
        .confirm('是否确认删除此分类？')
        .then(async () => {
          console.log('options', this.option);
          let id = data.id ? 'id' : this.option.value
          await this.option?.dialogOptions?.api?.remove([data[id]])
          this.getNewList()
          this.$message.success('删除分类成功！')
        })
    },
    //编辑树
    clickEdit() {
      this.isEdit = !this.isEdit
    },
    getNewList({ type, formData } = {}) {
      if (type === 'add') {
        this.handleEvent('init')
      } else if (type === 'update') {
        this.onRow = formData
        this.handleEvent('init', true)
      } else {
        this.onRow = null
        this.handleEvent('init', true)
      }
    },
    async nodeClick(e, load = false) {
      const value = e[this.option.value || 'value']
      if (typeof this.option.setData === 'function') {
        this.value = { ...this.value, ...this.option.setData(e) }
      } else {
        this.value = { ...this.value, [this.option.value]: value }
      }
      this.$emit('update:option', { ...this.option, check: e })
      if (load) {
        await this.$nextTick()
        this.$refs.tableTree?.setCurrentKey(value)
      }
      if (!this.option.notCallTableList || load) {
        this.handleEvent('change', e)
      }
    },
    async handleEvent(key, row) {
      console.log('vhandleEvent', key, row)
      switch (key) {
        case 'dialogChange':
          console.log('触发', this.dialogOptions)
          this.getNewList(this.dialogOptions)
          break
        case 'init':
          {
            this.show = false
            const res = await this.option.getListApi({
              ...this.option.defaultBody
            })
            if (typeof this.option.dataKey === 'function') {
              this.treeData = await this.option.dataKey(res)
            } else {
              this.treeData = res[this.option.dataKey || 'data']
            }
            await this.$nextTick()
            if (this.treeData?.length) {
              this.nodeClick(this.onRow || this.treeData[0], row)
            }
            this.show = true
          }
          break
        case 'change':
          await this.$nextTick()
          this.$emit('change', row)
          break
        case 'filterTree':
          this.$refs.tableTree.filter(row)
          break
        case 'recycleBin':
          this.dialogOptions = {
            ...this.dialogOptions,
            type: 'ShopInfo',
            show: true,
            title: '回收站',
            data: {
              getListApi: this.option.getListApi,
              restoreListApi: this.option?.dialogOptions?.api?.restore,
              id: 'vipBrandId',
              getDataKey: { list: "data" },
              pagination: {
                onlySelectTotal: true
              },
              search: [
                {
                  type: 'filters',
                  isRestore: true,
                  model: '',
                  filters: [
                    { filter: 'query', label: '全部' },
                    { filter: 'vipBrandNos', label: '会员品牌编号' },
                    { filter: 'vipBrandNames', label: '会员品牌名称' }
                  ]
                },
                {
                  type: 'button',
                  tip: '查询',
                  btnType: 'primary',
                  click: 'search',
                  isRestore: true
                }
              ],
              columns: [
                {
                  prop: 'vipBrandNo',
                  label: '会员品牌编号',
                  minWidth: 180
                },
                {
                  prop: 'vipBrandName',
                  label: '会员品牌名称',
                  minWidth: 180
                }
              ]
            }
          }
          break
        default:
          break
      }
    },
    filterNode(value, data) {
      if (!value.trim()) return true
      return data[this.option.label || 'label'].indexOf(value) !== -1
    }
  }
}
</script>

<style lang="scss" scoped>
.com-page {
  .header {
    padding: 8px;
    border-bottom: 1px solid #dfe6ec;
    .header-top {
      height: 30px;
      line-height: 30px;
      margin-bottom: 8px;
      //编辑
      .handleEdit {
        font-size: 14px;
        color: #1890ff;
        cursor: pointer;
      }
    }
  }

  .tree-view {
    height: calc(100% - 85px);
    overflow-x: hidden;
    .custom-tree-node {
      flex: 1;
      overflow: hidden; //溢出内容隐藏
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;

      .iconBtnTree {
        font-size: 14px;
        color: #999;
      }
    }
    /* 垂直滚动条样式 */
    /* 宽度 */
    &::-webkit-scrollbar {
      width: 8px;
    }
    /* 背景色 */
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
    }
    /* 滑块颜色 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.5);
    }
  }
}
.omit1 {
  text-overflow: -o-ellipsis-lastline !important;
  overflow: hidden !important; //溢出内容隐藏
  text-overflow: ellipsis !important; //文本溢出部分用省略号表示
  -webkit-line-clamp: 1 !important; //行数
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important; //盒子中内容竖直排列
  display: -webkit-box !important; //特别显示模式
}
</style>
