var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "upload",
      class:
        ((_obj = {}),
        (_obj[_vm.option.listType + "-uploader"] = true),
        (_obj["no-select"] =
          ["img", "imgs", "video"].includes(_vm.option.listType) && _vm.upload),
        _obj),
      attrs: {
        action: "#",
        multiple: false,
        "show-file-list": false,
        accept: _vm.myOption.type ? _vm.getAccept(_vm.myOption.type) : "",
        "http-request": function (file) {
          return _vm.httpRequest(file, "one")
        },
        "auto-upload": _vm.myOption.autoUpload,
        "on-change": _vm.onChange,
        "before-upload": _vm.beforeUpload,
        "file-list": _vm.fileList,
        disabled:
          _vm.upload ||
          (typeof _vm.myOption.disabled === "function"
            ? _vm.myOption.disabled()
            : _vm.myOption.disabled),
      },
    },
    [
      _vm.option.listType === "img"
        ? [
            _vm.myValue
              ? _c(
                  "div",
                  { staticClass: "img-avatar" },
                  [
                    _vm.myOption.clear
                      ? _c("i", {
                          staticClass: "el-icon-circle-close",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clearImg()
                            },
                          },
                        })
                      : _vm._e(),
                    _c(
                      "el-image",
                      {
                        key: _vm.upload + _vm.myValue,
                        staticClass: "img-avatar",
                        attrs: { src: _vm.myValue },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [_c("i", { staticClass: "el-icon-picture-outline" })]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c("i", { staticClass: "el-icon-plus img-uploader-icon" }),
          ]
        : _vm.option.listType === "video"
        ? [
            _vm.myValue
              ? _c("div", { staticClass: "video-avatar" }, [
                  _vm.myOption.clear
                    ? _c("i", {
                        staticClass: "el-icon-circle-close",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.clearImg()
                          },
                        },
                      })
                    : _vm._e(),
                  _c(
                    "video",
                    {
                      staticClass: "video-avatar",
                      attrs: {
                        autoplay: false,
                        second: 3.5,
                        controls: "",
                        loop: false,
                        playWidth: 20,
                      },
                    },
                    [
                      _c("source", {
                        attrs: { src: _vm.myValue, type: "video/mp4" },
                      }),
                      _vm._v(" Your browser does not support the video tag. "),
                    ]
                  ),
                ])
              : _c("i", { staticClass: "el-icon-plus img-uploader-icon" }),
          ]
        : _c(
            "el-button",
            {
              attrs: {
                slot: "trigger",
                size: "small",
                type: "primary",
                disabled:
                  _vm.upload ||
                  (typeof _vm.myOption.disabled === "function"
                    ? _vm.myOption.disabled()
                    : _vm.myOption.disabled),
              },
              slot: "trigger",
            },
            [_vm._v(" " + _vm._s(_vm.myOption.buttonName) + " ")]
          ),
      !_vm.myOption.autoUpload && !["img"].includes(_vm.option.listType)
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: {
                size: "small",
                type: "success",
                disabled: _vm.upload || !_vm.fileList.length,
              },
              on: {
                click: function ($event) {
                  return _vm.startUpload()
                },
              },
            },
            [_vm._v(" 上传 ")]
          )
        : _vm._e(),
      _vm.myOption.showList && !["img"].includes(_vm.option.listType)
        ? [
            _vm.upload
              ? _c("div", { staticClass: "upload" }, [
                  _c("span", { staticClass: "content" }, [
                    _c("i", {
                      staticClass: "el-icon-loading",
                      staticStyle: { "margin-right": "3px" },
                    }),
                    _vm._v(" " + _vm._s("正在上传：" + _vm.steep + "%") + " "),
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _c("i", {
                      staticClass: "el-icon-close",
                      on: {
                        click: function ($event) {
                          return _vm.cancelFile()
                        },
                      },
                    }),
                  ]),
                ])
              : _vm.fileList.length
              ? _c("div", { staticClass: "upload" }, [
                  _c("span", { staticClass: "content" }, [
                    _vm._v(_vm._s("已选择文件：" + _vm.fileList[0].name)),
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _c("i", {
                      staticClass: "el-icon-close",
                      on: {
                        click: function ($event) {
                          return _vm.delFile()
                        },
                      },
                    }),
                  ]),
                ])
              : _vm.myValue
              ? _c("div", { staticClass: "upload" }, [
                  _c("span", { staticClass: "content" }, [
                    _c("i", {
                      staticClass: "el-icon-circle-check",
                      staticStyle: { color: "#67c23a", "margin-right": "3px" },
                    }),
                    _vm._v(" " + _vm._s(_vm.myValue.split("/").pop()) + " "),
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _c("i", {
                      staticClass: "el-icon-document-copy",
                      on: {
                        click: function ($event) {
                          return _vm.copyFileUrl()
                        },
                      },
                    }),
                  ]),
                  _c("span", { staticClass: "text" }, [
                    _c("i", {
                      staticClass: "el-icon-close",
                      on: {
                        click: function ($event) {
                          return _vm.cancelFile()
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _c("el-input", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        attrs: { disabled: "" },
        model: {
          value: _vm.myValue,
          callback: function ($$v) {
            _vm.myValue = $$v
          },
          expression: "myValue",
        },
      }),
      _vm.getTip(_vm.myOption)
        ? _c(
            "div",
            {
              staticStyle: { "line-height": "20px" },
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [_vm._v(" " + _vm._s(_vm.getTip(_vm.myOption)) + " ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }