var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customTotal
    ? _c(
        "div",
        { staticClass: "customTotal" },
        _vm._l(_vm.customTotal.list, function (item, index) {
          return _c("span", { key: index }, [
            _vm._v(" " + _vm._s(item.title) + ": " + _vm._s(item.value) + " "),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }