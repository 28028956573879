<template>
  <el-dialog class="x-c center-dialog" :title="title" :visible.sync="showDialog" :width="width + 'px'"
    :before-close="handleClose" v-dialog-drag>
    <div class="x-start" :style="{ width: width - 40 + 'px', height: height + 'px' }">
      <div class="leftBox" v-if="isShowTree">
        <el-tree style="width: 290px" node-key="id" :data="treeOptions" :expand-on-click-node="false"
          :filter-node-method="filterNode" ref="tree" default-expand-all highlight-current @node-click="handleNodeClick"
          show-checkbox />
      </div>
      <div class="rightBox y-start x-f1" :class="isShowTree ? 'noShowTree' : 'w100_'">
        <div class="x-f marB10" v-if="isSearch">
          <el-input size="mini" v-model="keyboard" :placeholder="placeholder"></el-input>
          <el-button class="marL10" type="primary" size="mini" @click="search">查 询</el-button>
        </div>
        <el-table border ref="multipleTable" :data="tableData" style="width: 100%" :height="450"
          @row-click="handleRowClick" @select-all="tabSelectAll" @select="tabSelect"
          @selection-change="handleSelectionChange">
          <el-table-column v-if="isSelection" type="selection" width="50" align="center"></el-table-column>
          <el-table-column></el-table-column>
          <div v-for="(item, index) in tableHand" :key="index">
            <el-table-column align="center" :type="item.selection" :prop="item.prop" :label="item.label"
              :width="item.width" v-if="item.type == 'img'">
              <template v-slot="scope">
                <div class="x-c" v-if="scope.row['logoUrl']">
                  <el-image :src="scope.row['logoUrl']" class="itemImg"></el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" :type="item.selection" :prop="item.prop" :label="item.label"
              :width="item.width" v-else>
              <template slot-scope="{ row }">
                <span v-if="item.text">
                  {{ item.text(row) }}
                </span>
                <span v-else>
                  {{ row[item.prop] }}
                </span>
              </template>
            </el-table-column>
          </div>
        </el-table>
        <div style="width: 100%">
          <TablePagination :page.sync="config.pageNum" :limit.sync="config.pageSize" :total="config.total"
            @pagination="handleEvent"></TablePagination>
        </div>
      </div>
    </div>
    <span slot="footer" class="x-f-end">
      <el-button type="primary" class="marL10" @click="confirm">确 定</el-button>
      <el-button @click="close" class="marL10">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TablePagination from '@/components/tablePage/tablePagination'

export default {
  name: 'index',
  components: {
    TablePagination
  },
  // 列表组件
  props: {
    // 显示弹框
    // showDialog: {
    //   type: Boolean,
    //   default: false
    // },
    // 弹框标题
    title: {
      type: String,
      default: ''
    },
    // 弹框宽度
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 600
    },
    tableData: {
      type: Array,
      default: () => []
    },
    tableHand: {
      type: Array,
      default: () => []
    },
    // 是否显示勾选
    isSelection: {
      type: Boolean,
      default: false
    },
    // 是否单选（默认单选）
    isRadio: {
      type: Boolean,
      default: false
    },
    // 是否支持搜索
    isSearch: {
      type: Boolean,
      default: false
    },
    // 搜索框提示文字
    placeholder: {
      type: String,
      default: '请输入要搜索的字段'
    },
    isShowTree: {
      type: Boolean,
      default: false
    },
    // 分类树选项
    treeOptions: {
      type: Array,
      default: () => []
    },
    // 分页数据
    options: {
      type: Object,
      default: {}
    },
    // 列表搜索字段
    keyboard: {
      type: String,
      default: ''
    }
  },
  computed: {
    config: {
      get() {
        return this.options
      },
      set(val) {
        this.$emit('Device', val)
      }
    }
  },
  data() {
    return {
      selectData: [], // 选择的数据
      queryParams: {
        categoryId: 0 // 0：最外层服节点
      },

      showDialog: false,

      index: '' // 选中的下标
    }
  },
  methods: {
    //点击行选中
    handleRowClick(row) {
      if (this.isRadio) {
        // console.log('1111111', this.selectData)
        // this.selectData[0] = row
      } else {
        this.$refs.multipleTable.toggleRowSelection(row)
      }
    },
    //
    handleEvent() {
      // console.log('分页回调 1')
      this.$emit('handleEvent', this.config)
    },
    // 确定按钮
    confirm() {
      this.selectData.forEach(item => (item.index = this.index))
      this.$emit('confirm', this.selectData)
    },
    // 关闭弹框
    close(e) {
      // this.$emit('update:showDialog', false)
      this.showDialog = false

      e === true && this.$emit('update:showDialog')
    },

    // 打开弹出框
    onOpen(index) {
      this.index = index
      this.showDialog = true
    },

    handleClose(done) {
      this.$confirm('确认关闭？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(_ => {
          if (!this.levelType) {
            // this.$emit('update:showDialog', false)
            this.close(true)
          } else {
            // this.$emit('close', false)
            this.close()
          }
        })
        .catch(_ => { })
    },
    search() {
      this.$emit('search', this.keyboard)
    },
    // 选择列表
    handleSelectionChange(data) {
      this.selectData = data
      // console.log('选择的数据 = ', this.selectData)
    },
    tabSelectAll(selection) {
      if (this.isRadio) {
        if (selection.length > 1) {
          selection.length = 1
        }
      }
    },
    tabSelect(selection) {
      if (selection.length > 1) {
        if (this.isRadio) {
          // console.log("选择数量2：", selection.length);
          let del_row = selection.shift()
          this.$refs.multipleTable.toggleRowSelection(del_row, false)
        }
      }
    },
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 节点单击事件
    handleNodeClick(data) {
      console.log('点击的树：', data)
      this.queryParams.categoryId = data.id
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>
.leftBox {
  width: 300px;
  background-color: #ff3e54;
}

.rightBox {
  width: calc(100% - 300px);
}

.showTree {
  width: 290px;
}

.noShowTree {
  width: calc(100% - 300px);
  margin-left: 10px;
}

.itemImg {
  width: 40px;
  height: 40px;
}

.w100_ {
  width: 100%;
}

::v-deep .el-dialog__wrapper {
  padding-top: 150px;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  font-weight: bold;
}

::v-deep .el-dialog__footer {
  padding: 20px;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}

::v-deep .el-dialog__header {
  padding: 20px;
  height: 60px;
}

::v-deep .el-dialog__header {
  border-bottom: 0.5px solid #dcdcdc;
}

::v-deep .el-dialog__footer {
  border-top: 0.5px solid #dedede;
  padding: 10px 20px;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.center-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-dialog .el-dialog {
  margin: 0 auto;
}
</style>
