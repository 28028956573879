var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "weui-uploader x-f" }, [
      _vm.videoSrc
        ? _c(
            "video",
            { attrs: { id: "myPlayer", width: "200", controls: "", loop: "" } },
            [_c("source", { attrs: { src: _vm.videoSrc, type: "video/mp4" } })]
          )
        : _c("div", { staticStyle: { width: "80px", height: "80px" } }, [
            _c(
              "div",
              { staticClass: "weui-uploader__input-box protectiveScreen x-c" },
              [
                _c("input", {
                  ref: "file",
                  staticClass: "weui-uploader__input pointer",
                  staticStyle: { width: "80px", height: "80px", opacity: "0" },
                  attrs: {
                    id: "pop_video",
                    type: "file",
                    accept: "video/*",
                    name: "fileTrans",
                    multiple: "",
                  },
                  on: { change: _vm.getVideo },
                }),
                _c("i", { staticClass: "el-icon-plus pointer" }),
              ]
            ),
          ]),
      _vm.videoSrc
        ? _c("i", {
            staticClass: "el-icon-delete marL10",
            staticStyle: { color: "#ff0000", "font-size": "20px" },
            on: { click: _vm.deleteVideo },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }