<template>
  <el-select
    ref="selectAllFilter"
    v-model="modelValue"
    multiple
    filterable
    remote
    :placeholder="placeholder"
    :remote-method="debounceMethod"
    class="select-all-filter"
    popper-class="select-all-popper"
    value-key="value"
    no-data-text="加载中"
    :sunyunSelect="sunyunSelect"
    @visible-change="visibleChange"
    @change="change"
    size="mini"
  >
    <el-option
      v-for="item in data"
      :key="`${item.filter}.${item.valueLabel}`"
      :label="item.searchLabel"
      :value="item"
    >
    </el-option>
  </el-select>
</template>
<script>
import { mixin } from "@/components/tablePage/select/mixin/mixin-select.js";
import { deepCopy, isArray } from "@/utils";
export default {
  model: { prop: "value", event: "Device" },
  props: {
    filters: Array,
    value: [Object, Number, String, Array],
  },
  data() {
    return {
      placeholder: "",
      sunyunSelect: {
        tags: true,
        suffix: "el-icon-search",
      },
      debounce: null,
      data: [],
      modelValue: [],
    };
  },
  watch: {
    value: {
      handler() {
        const arr =
          deepCopy(this.filters)?.filter?.((x, i) =>
            i === 0 ? x.filter === "query" && x.noMerge : true
          ) || [];
        if (
          !arr.find((x) =>
            x.group
              ? isArray(this.value?.[x.group]?.[x.filter]) &&
                this.value?.[x.group]?.[x.filter]?.length
              : isArray(this.value?.[x.filter]) && this.value?.[x.filter]?.length
          )
        ) {
          this.modelValue = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.placeholder = this.filters?.map((x) => x.label).join("/");
  },
  methods: {
    debounceMethod: mixin.methods.debounceMethod,
    async remoteMethod(query) {
      if (query) {
        this.data = this.filters.map((x) => ({
          ...x,
          value: `${x.label}:${query}`,
          valueLabel: query,
          searchLabel: `${x.label}:${query}`,
        }));
        await this.$nextTick();
        this.$refs.selectAllFilter.hoverIndex = 0;
      }
    },
    change() {
      this.$nextTick(() => {
        const tags = this.$refs.selectAllFilter.$el.querySelector(
          ".el-select__tags span"
        );
        tags.scrollTo({ left: tags.scrollWidth, behavior: "smooth" });
      });
      const newObj = deepCopy(this.value) || {};
      const groups = {}; // 搜索字段分组   group xxx 一样则合并一起
      const arr = deepCopy(this.filters).filter((x, i) =>
        i === 0 ? x.filter === "query" && x.noMerge : true
      );
      arr.forEach((x, i) => {
        newObj[x.filter] = [];
        if (x.group) {
          groups[x.group] = [
            ...(isArray(groups[x.group]) ? groups[x.group] : []),
            x.filter,
          ];
        }
      });
      this.modelValue.forEach((x) => {
        if (x.filter === "query" && !newObj[x.filter]) {
          return arr.forEach((y) => {
            if (!newObj[y.filter].includes(x.valueLabel) && x.valueLabel) {
              newObj[y.filter] = [...newObj[y.filter], x.valueLabel];
            }
          });
        }
        if (!newObj[x.filter].includes(x.valueLabel) && x.valueLabel) {
          newObj[x.filter] = [...newObj[x.filter], x.valueLabel];
        }
      });
      arr.forEach((x) => {
        if (!isArray(newObj[x.filter]) || !newObj[x.filter]?.length) {
          delete newObj[x.filter];
        }
      });
      try {
        Object.keys(groups).map((key) => {
          groups[key].forEach((x) => {
            if (newObj[x]) {
              newObj[key] = { ...newObj[key], [x]: newObj[x] };
              delete newObj[x];
            }
          });
        });
      } catch (error) {
        console.log("filters groups err-", error);
      }
      this.$emit("Device", newObj);
      this.$emit("selectChange");
      this.data = [];
    },
    async visibleChange(visible) {
      if (!visible) {
        this.data = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-filter {
  width: 300px;
  ::v-deep .el-select__tags {
    left: 2px;
    & span:not(.el-tag, .el-select__tags-text):first-child {
      display: flex;
      display: flex;
      overflow-x: auto;
      scrollbar-width: none; /* Firefox */
      max-width: calc(100% - 55px) !important;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  ::v-deep .el-icon-more {
    cursor: pointer;
    &:hover {
      color: #409eff !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.select-all-popper {
  margin-top: 0px !important;
  .popper__arrow {
    display: none;
  }
  .el-scrollbar__view {
    padding: 0 !important;
  }

  .hover {
    background-color: #e9f2fd !important;
    color: #2687ee !important;
  }
}
</style>
