<template>
    <div class="">

        <div class="weui-uploader x-f">
            <!-- <video v-if="videoSrc" class="videoBox" id="videoId" :src="videoSrc" width="100%"></video> -->
            <video id="myPlayer" v-if="videoSrc" width="200" controls loop><source :src="videoSrc" type="video/mp4" ></video>
            <div style="width: 80px;height: 80px;" v-else>
                <div class="weui-uploader__input-box protectiveScreen x-c">
                    <input id="pop_video" style="width: 80px;height: 80px;opacity:0;"
                           class="weui-uploader__input pointer" type="file"
                           v-on:change="getVideo" accept="video/*" name="fileTrans" ref="file" multiple=""
                    >
                    <i class="el-icon-plus pointer"></i>
                </div>
            </div>
            <i v-if="videoSrc"  class="el-icon-delete marL10" style="color: #ff0000;font-size: 20px" @click="deleteVideo"></i>
        </div>
<!--        <span>视频建议</span>-->
    </div>
</template>

<script>
import { uploadVideo } from '@/api/O2OMall/goods/list'

export default {
    name: 'UploadVideo',
    components: {},
    props: {
        video: {
            request: true
        },
        numLimit: {
            // 最大允许上传个数
            type: Number,
            default: 1
        },
        sizeLimit: {
            // 最大单文件大小
            type: Number,
            default: 50
        },
        fileList: {
            // 已上传图片服务器路径
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            loading: false,
            dialogVisible: false,
            videoList: [],
            videoSrc: '',
            actionUrl:  process.env.VUE_APP_BASE_API + '/api/open/oss/video/upload',
            // actionUrl: 'http://admin.balingsoft.com/api/open/oss/video/upload',

            uploadVideoList: [], // 选中的上传视频列表
            // videoSrc: '', // 视频连接
            filesId: null

        }
    },
    created() {

    },
    watch:{
        video:{
            handler(n ,l){
              console.log('视频文件：', this.video)
                this.videoSrc = this.video.accessUrl
            },
            deep:true
        }
    },
    methods: {
        getVideo() {
            let taht = this
            //获取上传文件标签
            this.filesId = document.getElementById('pop_video')
            //获取音频标签
            // let videoId = document.getElementById('videoId');
            //把当前files[0]传给getFileURL方法， getFileURL方法对其做一处理
            let url = this.getFileURL(this.filesId.files[0])
            console.log('视频大小 = ', this.filesId.files[0])
            if (url) {
                console.log('视频路径：', url)
                //给video标签设置src
                this.videoSrc = url
                this.SubBtn()
            }
        },
        getFileURL(file) {
            console.log('获取视频文件', file)
            let getUrl = null
            if (window.createObjectURL != undefined) {
                getUrl = window.createObjectURL(file)
            } else if (window.URL != undefined) {//window.URL 标准定义
                getUrl = window.URL.createObjectURL(file)
            } else if (window.webkitURL != undefined) {//window.webkitURL是webkit的内核
                getUrl = window.webkitURL.createObjectURL(file)
            }
            console.log('获取视频文件路径', getUrl)
            return getUrl
        },
        SubBtn() {
            let formData = new FormData()
            formData.append('video', this.filesId.files[0])
            console.log('视频文件上传：', formData)
            // 提交服务器
            uploadVideo(formData).then(res => {
                this.$message.success('上传视频成功')
                console.log('上传视频===', res)
                this.$emit('getVideo', res.data)
            })
        },
        // 删除视频
        deleteVideo() {
            this.videoSrc = ''
            this.video.accessUrl = ''
            this.$emit('deleteVideo', this.video.videoId)
        }
    }
}
</script>

<style lang="scss" scoped>
.videoBox {
    width: 80px;
    height: 80px;
}

.upLoad {
    background-color: #fff;
    /* height: 1.5rem; */
    text-align: left;
    padding: 0.3rem;
}

.inputVideo {
    background-color: #00cc66;
    color: #fff;
    font-size: 0.32rem;
    width: 30%;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.4rem;
    text-align: center;
    margin: 10px auto;
}

#inspect .inputAudio {
    background-color: #5cadff;
    color: #fff;
    font-size: 0.32rem;
    width: 30%;
    height: 0.8rem;
    line-height: 0.8rem;
    border-radius: 0.4rem;
    text-align: center;
    margin: 0 auto;
}

.protectiveScreen {
    background-color: #fbfdff;
    z-index: -1;
    border: 1px dashed #c0ccda;
    border-radius: 6px;

    i::before {
        z-index: 99;
        margin-left: -50px;
        font-size: 28px;
        color: #666666;
    }

}

</style>
