<template>
  <el-upload
    ref="upload"
    :class="{
      [`${option.listType}-uploader`]: true,
      'no-select': ['img', 'imgs', 'video'].includes(option.listType) && upload,
    }"
    action="#"
    :multiple="false"
    :show-file-list="false"
    :accept="myOption.type ? getAccept(myOption.type) : ''"
    :http-request="(file) => httpRequest(file, 'one')"
    :auto-upload="myOption.autoUpload"
    :on-change="onChange"
    :before-upload="beforeUpload"
    :file-list="fileList"
    :disabled="
      upload ||
      (typeof myOption.disabled === 'function' ? myOption.disabled() : myOption.disabled)
    "
  >
    <template v-if="option.listType === 'img'">
      <div v-if="myValue" class="img-avatar">
        <i v-if="myOption.clear" class="el-icon-circle-close" @click.stop="clearImg()" />
        <el-image :key="upload + myValue" :src="myValue" class="img-avatar">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline" />
          </div>
        </el-image>
      </div>
      <i v-else class="el-icon-plus img-uploader-icon" />
    </template>
    <template v-else-if="option.listType === 'video'">
      <div v-if="myValue" class="video-avatar">
        <i v-if="myOption.clear" class="el-icon-circle-close" @click.stop="clearImg()" />
        <video
          class="video-avatar"
          :autoplay="false"
          :second="3.5"
          controls
          :loop="false"
          :playWidth="20"
        >
          <source :src="myValue" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <i v-else class="el-icon-plus img-uploader-icon" />
    </template>
    <el-button
      v-else
      slot="trigger"
      size="small"
      type="primary"
      :disabled="
        upload ||
        (typeof myOption.disabled === 'function'
          ? myOption.disabled()
          : myOption.disabled)
      "
    >
      {{ myOption.buttonName }}
    </el-button>
    <el-button
      v-if="!myOption.autoUpload && !['img'].includes(option.listType)"
      style="margin-left: 10px"
      size="small"
      type="success"
      :disabled="upload || !fileList.length"
      @click="startUpload()"
    >
      上传
    </el-button>
    <template v-if="myOption.showList && !['img'].includes(option.listType)">
      <div v-if="upload" class="upload">
        <span class="content">
          <i class="el-icon-loading" style="margin-right: 3px"></i>
          {{ `正在上传：${steep}%` }}
        </span>
        <span class="text">
          <i class="el-icon-close" @click="cancelFile()"></i>
        </span>
      </div>
      <div v-else-if="fileList.length" class="upload">
        <span class="content">{{ "已选择文件：" + fileList[0].name }}</span>
        <span class="text">
          <i class="el-icon-close" @click="delFile()"></i>
        </span>
      </div>
      <div v-else-if="myValue" class="upload">
        <span class="content">
          <i class="el-icon-circle-check" style="color: #67c23a; margin-right: 3px"></i>
          {{ myValue.split("/").pop() }}
        </span>
        <span class="text">
          <i class="el-icon-document-copy" @click="copyFileUrl()"></i>
        </span>
        <span class="text">
          <i class="el-icon-close" @click="cancelFile()"></i>
        </span>
      </div>
    </template>
    <el-input v-show="false" disabled v-model="myValue" />
    <div slot="tip" style="line-height: 20px" v-if="getTip(myOption)">
      {{ getTip(myOption) }}
    </div>
  </el-upload>
</template>
<script>
import { ossUpload } from "@/api/ossfile.js";
import { haveContent } from "@/utils";
export default {
  name: "OssUpload",
  props: {
    width: {
      type: [String, Number],
    },
    height: {
      type: [String, Number],
    },
    value: {
      type: [String, Array, Object],
    },
    option: {
      // 下面的全部配置 优先级高
      type: [Object, String],
      default: "",
    },
    type: {
      // 限制文件类型 eq: jpg / exe / png  ||  ['png', 'jpg']
      type: [String, Array],
    },
    size: {
      // 限制文件大小 eq: 20kb / 20 mb 2mb
      type: [String, Array],
    },
    autoUpload: {
      // 是否自动上传
      type: Boolean,
      default: true,
    },
    buttonName: {
      // 按钮名称
      type: String,
      default: "选择文件",
    },
    tip: {
      // 是否显示提示
      type: [Boolean, String],
      default: true,
    },
    showList: {
      // 是否展示文件列表
      type: Boolean,
      default: true,
    },
    message: {
      // 是否显示操作消息
      type: Boolean,
      default: true,
    },
    listType: {
      // 组件样式： img 组件 |
      type: String,
      default: "",
    },
    validate: {
      // 自定义文件限制
      type: [Function, String],
      default: "",
    },
    clear: {
      // 是否删除图片
      type: Boolean,
      default: false,
    },
    disabled: {
      // 是否禁用
      type: [Boolean, Function],
      default: false,
    },
    useFileName: {
      // 是否使用原文件名
      type: Boolean,
      default: false,
    },
    customName: {
      // 是否自定义路径和文件名
      type: String,
      default: "",
    },
  },
  model: { prop: "value", event: "Device" },
  data() {
    return {
      fileList: [],
      getAccept: (e) => {
        const types = Array.isArray(e) ? e : [e];
        return types.map((x) => `.${x}`).join(",");
      },
      getTip: ({ type, size, tip, width, height } = {}) => {
        let t = "";
        if (tip) {
          if (typeof tip === "string") {
            t = tip;
          }
          if (type?.length) {
            const types = Array.isArray(type) ? type : [type];
            const typeStr = types.join("/");
            t = `${t}只能上传 ${typeStr} 类型文件`;
          }
          if (haveContent(width) || haveContent(height)) {
            let maxW = Number(String(width).replace(/\D/g, ""));
            maxW = Number.isFinite(maxW) ? maxW : 0;
            if (maxW > 0) {
              t = `${t}${t ? "，" : ""}宽不能超过 ${maxW}px`;
            }
            let maxH = Number(String(height).replace(/\D/g, ""));
            maxH = Number.isFinite(maxH) ? maxH : 0;
            if (maxH > 0) {
              t = `${t}${t ? "，" : ""}高不能超过 ${maxH}px`;
            }
          }
          if (size) {
            t = `${t}${t ? "，" : ""}大小不超过 ${size}`;
          }
        }
        return t;
      },
      upload: false,
      cancel: false,
      steep: 0,
    };
  },
  computed: {
    myValue: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("Device", e);
      },
    },
    myOption: {
      get() {
        return {
          type: this.type,
          size: this.size,
          width: this.width,
          height: this.height,
          autoUpload: this.autoUpload,
          tip: this.tip,
          showList: this.showList,
          listType: this.listType,
          message: this.message,
          useFileName: this.useFileName,
          validate: this.validate,
          disabled: this.disabled,
          buttonName: this.buttonName,
          customName: this.customName,
          clear: this.clear,
          ...this.option,
        };
      },
    },
  },
  methods: {
    clearImg() {
      this.myValue = "";
    },
    cancelFile() {
      // 取消上传 删除文件
      this.cancel = true;
      this.$emit("Device", "");
    },
    startUpload() {
      let ref = this.$refs.upload?.[0] || this.$refs.upload;
      ref.submit();
    },
    async httpRequest(file) {
      const { change, message, useFileName, customName } = this.myOption;
      try {
        this.upload = true;
        this.cancel = false;
        this.steep = 0;
        const onProgress = (e) => {
          this.steep = Number(e).toFixed(2);
        };
        const onCancel = () => this.cancel;
        const res = await ossUpload(file, {
          config: {
            useFileName,
            customName,
          },
          onProgress,
          onCancel,
        });
        this.myValue = res.fileUrl;
        if (typeof change === "function") {
          change(res);
        }
        this.$emit("change", res);
        this.fileList = [];
        if (message) {
          this.$message.success("上传成功");
        }
      } catch (error) {
        console.log("upload err---", error);
        if (message) {
          if (error?.name === "cancel") {
            this.$message.error("已取消上传");
          } else {
            this.$message.error("上传文件失败");
          }
        }
      }
      this.upload = false;
    },
    copyFileUrl() {
      // https://developer.mozilla.org/zh-CN/docs/Web/API/Navigator/clipboard
      try {
        navigator.clipboard.writeText(this.myValue);
        this.$message.success("复制成功");
      } catch (error) {
        try {
          const input = document.createElement("input"); // 创建input标签
          input.value = this.myValue; // 将input的值设置为需要复制的内容
          document.body.appendChild(input); // 添加input标签
          input.select(); // 选中input标签
          document.execCommand("copy"); // 执行复制
          document.body.removeChild(input); // 移除input标签
          this.$message.success("复制成功"); // 成功提示信息
        } catch (error) {}
      }
    },
    delFile() {
      this.fileList = [];
    },
    async beforeUpload(e) {
      console.log("beforeUpload");
      const { type, size, width, height, validate } = this.myOption;
      const { name: fileName, size: fileSize } = e;
      if (typeof validate === "function" && !validate(e)) {
        return Promise.reject();
      }
      if (type?.length) {
        const types = Array.isArray(type) ? type : [type];
        if (
          !types
            .map((x) => x.toLowerCase())
            .includes(fileName.split(".").pop().toLowerCase())
        ) {
          this.$message.error(
            `上传文件失败：只能上传${
              Array.isArray(types) ? types.join("、") : ""
            }类型文件`
          );
          return Promise.reject();
        }
      }
      // 限制宽高
      if (haveContent(width) || haveContent(height)) {
        let maxW = Number(String(width).replace(/\D/g, ""));
        maxW = Number.isFinite(maxW) ? maxW : 0;
        let maxH = Number(String(height).replace(/\D/g, ""));
        maxH = Number.isFinite(maxH) ? maxH : 0;
        if (maxW > 0 || maxH > 0) {
          const getImgInfo = (src) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => {
                resolve({ width: img.width, height: img.height });
              };
              img.onerror = reject;
              img.src = URL.createObjectURL(src);
            });
          const imgInfo = await getImgInfo(e);
          if (maxW > 0 && imgInfo.width > maxW) {
            this.$message.error(`上传文件失败：文件宽不能超过 ${maxW}px`);
            return Promise.reject();
          }
          if (maxH > 0 && imgInfo.height > maxH) {
            this.$message.error(`上传文件失败：文件高不能超过 ${maxW}px`);
            return Promise.reject();
          }
        }
      }
      if (size) {
        let upSize = size.replace(/\s/g, "").toLowerCase();
        const maxU = upSize
          .replace(/[^a-z]/g, "")
          .trim()
          .split("")[0];
        const maxS = Number(upSize.split(maxU)[0]);
        const sizes = {
          k: 1024,
          m: 1024 * 1024,
          g: 1024 * 1024 * 1024,
        };
        if (fileSize > maxS * sizes[maxU]) {
          this.$message.error(`上传文件失败：文件不能大于 ${size}`);
          return Promise.reject();
        }
      }
      return true;
    },
    async onChange(e) {
      if (e.status === "ready") {
        console.log("onChange");
        this.fileList = [e]; // 只能上传一个文件
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.upload {
  width: 100%;
  margin: 3px 0;
  padding: 0 3px;
  border-radius: 3px;
  overflow: hidden;
  height: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333333;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.08);
    cursor: pointer;
  }

  .content {
    flex: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* 设置最大显示行数 */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    width: calc(100% - 32px);
    word-break: break-all;
  }

  .text {
    width: 16px;
    min-width: 16px;
    text-align: center;
    &:hover {
      color: red;
    }
  }
}

.no-select {
  ::v-deep .el-upload {
    -webkit-user-select: none; /* webkit浏览器 */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    user-select: none; /* 标准语法 */
    pointer-events: none;
  }
}
.img-uploader,
.imgs-uploader,
.video-uploader {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
    }
  }
}

.img-tool {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.img-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 98px;
  height: 98px;
  line-height: 98px;
  text-align: center;
}

.img-avatar,
.video-avatar {
  width: 98px;
  height: 98px;
  display: block;
  position: relative;
  .el-icon-circle-close {
    font-size: 22px;
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    display: none;
    color: #858585;
  }
}
.video-avatar {
  width: 155px;
  height: 155px;
}
.img-avatar:hover,
.video-avatar:hover {
  .el-icon-circle-close {
    display: block !important;
  }
}
.el-icon-circle-close:hover {
  color: #409eff !important;
}
</style>
