<template>
  <div class="buy-step-com" v-loading="loading">
    <div class="top-btns">
      <el-button class="marT5" size="small" v-if="[1, 2].includes(step)" @click="updateStep(true)">下一步</el-button>
      <el-button class="marT5" size="small" v-if="
          [1, 2, 3].includes(step) && !(resumeInfo.orderNo && resumeInfo.money)
        " @click="updateStep()">后退</el-button>
      <el-button class="marT5" size="small" v-if="resumeInfo.orderNo && resumeInfo.money"
        @click="getBackOrderList">返回</el-button>
      <el-button class="marT5" size="small" v-if="step === 4" @click="updateStep(true)">完成</el-button>
    </div>

    <cardTitleCom cardTitle="当前进度">
      <template slot="cardContent">
        <el-steps :active="step" align-center class="step">
          <el-step v-for="title in ['选择产品规格', '确认订单', '去支付', '支付成功']" :key="title" :title="title"></el-step>
        </el-steps>
      </template>
    </cardTitleCom>

    <cardTitleCom cardTitle="产品规格" v-show="step === 1">
      <template slot="cardContent">
        <div class="x-b marT10 marB10" style="width:300px;margin-left:50px">
          <span class="fontS15">产品价格</span>
          <span class="asterisk fontBold">{{`${productForm.productPrice||0}元`}}</span>
          <!-- <span>{{productForm.productOldPrice}}</span> -->
        </div>
        <myForm v-model="productForm" ref="myForm" :options="productFormOption" label-width="120px" />
      </template>
    </cardTitleCom>

    <div v-show="step === 2" v-if="orderDetail">
      <cardTitleCom cardTitle="产品规格">
        <template slot="cardContent">
          <el-descriptions :column="1" labelClassName="my-descriptions-label">
            <el-descriptions-item label="服务名称">{{`开通${appTypeName}服务`}}</el-descriptions-item>
            <el-descriptions-item label="规格">
              <span v-if="productForm.userNumber > 0">{{ productForm.userNumber }}个员工账户</span>
              <span v-if="productForm.shopNumber > 0">
                <span v-if="productForm.userNumber > 0">+</span>{{ productForm.shopNumber }}个门店数</span>
              <span v-if="productForm.possNumber > 0">
                <span v-if="
                    productForm.userNumber > 0 || productForm.shopNumber > 0
                  ">+</span>{{ productForm.possNumber }}个站点数</span>
            </el-descriptions-item>
            <el-descriptions-item label="有效期">{{
              orderDetail.unit
            }}</el-descriptions-item>
            <el-descriptions-item label="原价">{{
                orderDetail.oldOrderMoney.toFixed(2)
              }}元</el-descriptions-item>
            <el-descriptions-item label="优惠券">无可用优惠券</el-descriptions-item>
            <el-descriptions-item label="合计">{{ orderDetail.orderMoney.toFixed(2) }}元</el-descriptions-item>
            <el-descriptions-item label="联系人">{{
              orderDetail.linkMan
            }}</el-descriptions-item>
            <el-descriptions-item label="联系人电话">{{
              orderDetail.linkManTel
            }}</el-descriptions-item>
          </el-descriptions>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="产品规格">
        <template slot="cardContent">
          <el-checkbox v-model="checked" style="margin-left: 20px"></el-checkbox>
          <span style="font-size: 12px; margin-left: 5px; line-height: 23px">
            我已阅读并同意
            <el-link type="primary" style="font-size: 12px">《合同条款》</el-link>
          </span>
          <div style="height: 80px"></div>
        </template>
      </cardTitleCom>
    </div>

    <cardTitleCom cardTitle="支付方式" v-show="step === 3" v-if="orderDetail || (resumeInfo.orderNo && resumeInfo.money)">
      <template slot="cardContent">
        <div class="pay-code">
          <div style="line-height: 30px; padding: 15px 0; font-size: 16px">
            应付金额：
            <span style="font-size: 30px; color: #ff5b28">{{
                resumeInfo.money ? resumeInfo.money : orderDetail.orderMoney
              }}元</span>
          </div>
          <img :src="QRImgUrl" class="QRImgUrl" style="width: 180px; height: 180px" />
          <div style="
              color: #666666;
              font-size: 14px;
              padding: 15px 0;
              display: flex;
              algin-item: center;
              justify-content: center;
            ">
            <el-image :src="require('@/assets/images/weixinpay.png')" class="icon" />
            <div>微信支付</div>
            <el-image :src="require('@/assets/images/zhifubaopay.png')" class="icon" />
            <div>支付宝支付</div>
            <el-image :src="require('@/assets/images/yunshanpay.png')" class="icon" />
            <div>云闪付</div>
          </div>
          <div style="color: #666666; font-size: 14px; padding: 15px 0">
            温馨提示：产品一经激活启用，非产品原因不允许退货退款
          </div>
        </div>
      </template>
    </cardTitleCom>

    <cardTitleCom cardTitle="支付方式" v-if="step === 4">
      <div class="pay-code" slot="cardContent">
        <i class="el-icon-circle-check" style="color: #28ac3f; font-size: 48px; margin: 15px"></i>
        支付成功
        <div>
          <el-link type="primary" @click="$emit('update:active', 3)">查看订单详情</el-link>
        </div>
      </div>
    </cardTitleCom>
  </div>
</template>
<script>
import { deepCopy, fcount } from '@/utils' //复制、浮点
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import myForm from "@/components/my-form";
export default {
  name: "buyStep",
  components: { cardTitleCom, myForm },
  props: {
    resumeInfo: {
      type: Object,
      default: () => ({}),
    },
    options: { type: Object, required: true, default: () => ({}), },
  },
  data() {
    return {
      QRImgUrl: "",
      checked: false,
      loading: true,
      step: 1,
      payImg: "",
      productFormOption: [],
      productForm: {},
      clearTimerResume: null, //支付中的单据
      timeing: 0, // 监控之间`
      timer: null, // 支付状态计时器
      appTypeNameInfo: {
        1: '营业通',
        2: '裱花间',
        3: '点餐小程序',
        4: '蛋糕自选',
      }
    };
  },
  computed: {
    // 计算用户订详情
    orderDetail: function () {
      const oneKey = 'duration'
      const twoKey = 'userNumber'
      const threeKey = 'shopNumber'
      const possKey = 'possNumber'

      const oneData = this.yearsData

      const val = this.productForm

      const {
        productOldPrice,
        productPrice,
        label,
        productId: pdID,
        payModelId: pmID,
        initializeUsers,
        initializeShops,
        initializePoss,
        saasProductUsersPrices,
        saasProductShopsPrices,
        saasProductPossPrices,
        initializeShopsPrice, // 初始单价
        initializeUsersPrice, // 初始单价
        initializePossPrice,
        isManageUsers,
        isManageShops,
        isManagePoss
      } = oneData?.find(x => x.value === val[oneKey]) || {}
      this.productForm.money = '0' + '元'
      if (!this.productForm[oneKey] || !pdID) {
        return null
      }
      let money = 0 // 订单价格
      let oldMoney = 0 // 订单原价 前端用
      let unit = '' // 单位 前端用
      let payModelId = null // 包年付费方式ID
      let productId = null // 购买产品ID
      let shopsPriceId = null // 门店数价格ID
      let usersPriceId = null // 用户数价格ID
      let possPriceId = null // 站点数价格ID

      let usersOldMoney = 0 // 用户原价  前端用
      let usersMoney = 0 // 用户支付价格  前端用
      let showUsersPrice = 0 // 用户展示的单价

      let shopsOldMoney = 0 // 门店原价  前端用
      let shopsMoney = 0 // 门店支付价格  前端用
      let showShopPrice = 0 // 门店展示的单价

      let possOldMoney = 0 // 站点原价  前端用
      let possMoney = 0 // 站点支付价格  前端用
      let showPossPrice = 0 // 站点展示的单价
      // 购买时常价格

      if (label && pdID) {
        unit = label
        productId = pdID
        payModelId = pmID
      } else {
        return null
      }

      // 购买人数价格
      if (saasProductUsersPrices?.[0]?.usersPrice && isManageUsers) {
        let twoData = saasProductUsersPrices?.sort?.(
          (a, b) => b.users - a.users
        )
        const {
          usersPrice = 0,
          usersOldPrice = 0,
          usersPriceId: upId
        } = twoData?.find(x => val[twoKey] - initializeUsers >= x.users) || {}

        usersOldMoney = isManageUsers
          ? fcount(
            [
              usersOldPrice,
              fcount([this.productForm[twoKey], initializeUsers], '-')
            ],
            '*'
          )
          : 0 // 用户原价  前端用
        usersMoney = isManageUsers
          ? fcount(
            [
              usersPrice,
              fcount([this.productForm[twoKey], initializeUsers], '-')
            ],
            '*'
          )
          : 0 // 用户支付价格  前端用
        showUsersPrice = isManageUsers ? usersPrice : 0 // 用来展示的用户单价
        money = fcount(
          [usersMoney, fcount([initializeUsersPrice, initializeUsers], '*')],
          '+'
        )
        oldMoney = fcount(
          [usersOldMoney, fcount([initializeUsersPrice, initializeUsers], '*')],
          '+'
        )
        money = usersMoney + initializeUsersPrice * initializeUsers
        oldMoney = usersOldMoney + initializeUsersPrice * initializeUsers
        usersPriceId = upId
      }

      // 购买门店价格
      if (saasProductShopsPrices?.[0]?.shopsPrice && isManageShops) {
        let threeData = saasProductShopsPrices?.sort?.(
          (a, b) => b.shops - a.shops
        )
        const {
          shopsPrice = 0,
          shopsOldPrice = 0,
          shopsPriceId: spId
        } = threeData?.find(x => val[threeKey] - initializeShops >= x.shops) ||
          {}
        shopsOldMoney = isManageShops
          ? fcount(
            [
              shopsOldPrice,
              fcount([this.productForm[threeKey], initializeShops], '-')
            ],
            '*'
          )
          : 0 // 用户原价  前端用
        shopsMoney = isManageShops
          ? fcount(
            [
              shopsPrice,
              fcount([this.productForm[threeKey], initializeShops], '-')
            ],
            '*'
          )
          : 0 // 用户支付价格  前端用
        showShopPrice = isManageShops ? shopsPrice : 0
        money = money + shopsMoney + initializeShopsPrice * initializeShops
        oldMoney =
          oldMoney + shopsOldMoney + initializeShopsPrice * initializeShops
        shopsPriceId = spId
        console.log('spId', spId, val[threeKey], initializeShops, threeData);
      }

      // 购买站点价格
      if (saasProductPossPrices?.[0]?.possPrice && isManagePoss) {
        let threeData = saasProductPossPrices?.sort?.((a, b) => b.poss - a.poss)
        const {
          possPrice = 0,
          possOldPrice = 0,
          possPriceId: ppId
        } = threeData?.find(x => val[possKey] - initializePoss >= x.poss) || {}
        possOldMoney = isManagePoss
          ? fcount(
            [
              possOldPrice,
              fcount([this.productForm[possKey], initializePoss], '-')
            ],
            '*'
          )
          : 0 // 用户原价  前端用
        possMoney = isManagePoss
          ? fcount(
            [
              possPrice,
              fcount([this.productForm[possKey], initializePoss], '-')
            ],
            '*'
          )
          : 0 // 用户支付价格  前端用
        showPossPrice = isManagePoss ? possPrice : 0
        money = money + possMoney + initializePossPrice * initializePoss
        oldMoney =
          oldMoney + possOldMoney + initializePossPrice * initializePoss
        possPriceId = ppId
      }
      // 去下浮点
      oldMoney = fcount([this.productForm.productOldPrice, oldMoney], '+')
      money = fcount([money, this.productForm.productPrice], '+')
      money = money % 1 === 0 ? money : Number(money.toFixed(2))
      oldMoney = oldMoney % 1 === 0 ? oldMoney : Number(oldMoney.toFixed(2))

      this.productForm.money = money + '元'
      const obj = {
        ...val,
        orderMoney: money,
        oldOrderMoney: oldMoney,
        unit,
        payModelId,
        productId,
        shopsPriceId,
        usersPriceId,
        possPriceId,
        shopsQty: val[threeKey],
        usersQty: val[twoKey],
        possQty: val[possKey],
        usersOldMoney,
        usersMoney,
        shopsOldMoney,
        shopsMoney,
        possOldMoney,
        possMoney,
        showUsersPrice,
        showShopPrice,
        showPossPrice,
        initializeUsers,
        initializeShops,
        initializePoss,
        linkManTel: String(val.linkManTel)
      }
      this.QRImgUrl = ''

      return obj
    },
    appTypeName() {
      //1营业通 2裱花间 3点餐 4自选
      return this.appTypeNameInfo[this.options.productAPPType] || '营业通'
    }
  },
  watch: {
    "productForm.duration": {
      handler(newDuration) {
        if (this.showForm && newDuration) {
          console.log('newDuration', newDuration);
          this.updateForm(
            "duration",
            this.productData.payModelItems.findIndex(
              (x) => x.payModelType === newDuration
            )
          );
        }
      },
    }
  },
  async mounted() {
    try {
      if (this.resumeInfo && this.resumeInfo.orderNo && this.resumeInfo.money) {
        this.step = 3;
        const imgRes = await this?.options?.orderPayApi({
          ordNo: this.resumeInfo.orderNo,
          amt: this.resumeInfo.money,
          subject: `${this.appTypeName}开通`,
        });
        this.QRImgUrl = imgRes.data;
        this.clearTimerResume = () => clearInterval(this.timer);
        // 每秒检测一次
        this.timer = setInterval(async () => {
          // 一分钟计时
          if (this.timeing <= 60) {
            const res = await this?.options?.getOrderStateApi({
              ordNo: this.resumeInfo.orderNo,
            });
            if (res?.data) {
              // 支付成功
              this.clearTimerResume();
              this.step = 4;
            }
          } else {
            this.clearTimerResume();
            this.$message.warning("支付超时！");
            this.$emit("update:active", 1);
          }
          this.loading = false;
          this.timeing = this.timeing + 1;
        }, 1000);
      } else {
        this.step = 1;
        this.clearTimerResume = null;
        const res = await this?.options?.getOrderDetailApi();
        if (res.data) {
          this.productData = res.data;
          // 取折扣后金额
          const { data } = await this.getDicts("product_years_package_mode");
          this.dictRes = data;
          this.updateForm();
        }
        this.loading = false;
      }
    } catch (error) {
      console.log("Errrrrr", error);
      this.loading = false;
    }
    // 获取价格
  },
  methods: {
    //返回
    getBackOrderList() {
      if (this.clearTimerResume) {
        this.clearTimerResume();
      }
      this.$emit("update:active", 3);
    },
    async updateForm(key, value) {
      if (key) {
        this[key] = value
      }
      const isDuration = key === 'duration'
      try {
        this.showForm = false
        const {
          payModelItems,
          maxUsers,
          maxShops,
          maxPoss,
          productPrice,
          productOldPrice
        } = this.productData

        if (!isDuration) {
          this.yearsData = payModelItems.map(x => {
            const item = this.dictRes.find(y => y.dictValue === x.payModelType)
            return {
              ...x,
              productPrice,
              productOldPrice,
              value: x.payModelType,
              label: item.dictLabel
            }
          })
        }
        const {
          saasProductUsersPrices,
          saasProductShopsPrices,
          saasProductPossPrices,

          initializeUsersPrice,
          initializeShopsPrice,
          initializePossPrice,

          initializeShops,
          initializeUsers,
          initializePoss,

          isManageUsers,
          isManageShops,
          isManagePoss,

          value: durationValue
        } = this.yearsData[isDuration ? value : 0]

        const userShops = [
          saasProductUsersPrices?.[0]?.usersPrice && isManageUsers
            ? {
              model: 'userNumber',
              type: 'input-number',
              label: '在线用户数',
              append: {
                type: 'xyt-users',
                show: true,
                isInitial: true,
                getPrice: () => ({
                  unit: this.orderDetail.unit,
                  showUsersPrice: this.orderDetail.showUsersPrice,
                  orderMoney:
                    this.orderDetail.usersMoney +
                    initializeUsers * initializeUsersPrice,
                  oldOrderMoney:
                    this.orderDetail.usersOldMoney >
                      this.orderDetail.usersMoney
                      ? this.orderDetail.usersOldMoney +
                      initializeUsers * initializeUsersPrice
                      : ''
                })
              },
              data: saasProductUsersPrices,
              min: initializeUsers > 0 ? initializeUsers : 1, //最小用户数
              max: maxUsers,
              initializeUsers: initializeUsers,
              initializeUsersPrice: initializeUsersPrice,
              precision: 0,
              rules: [
                {
                  required: true,
                  message: '请输入在线用户数',
                  trigger: ['blur', 'change']
                }
              ]
            }
            : null,
          saasProductShopsPrices?.[0]?.shopsPrice && isManageShops
            ? {
              model: 'shopNumber',
              type: 'input-number',
              label: '门店个数',
              data: saasProductShopsPrices,
              min: initializeShops > 0 ? initializeShops : 1, // 为0的时候，默认1个，大于0时则为初始用户
              max: maxShops,
              initializeShops: initializeShops,
              initializeShopsPrice: initializeShopsPrice,
              precision: 0,
              append: {
                type: 'xyt-users',
                show: true,
                isInitial: true,
                getPrice: () => ({
                  showShopPrice: this.orderDetail.showShopPrice,
                  unit: this.orderDetail.unit,
                  orderMoney:
                    this.orderDetail.shopsMoney +
                    initializeShops * initializeShopsPrice,
                  oldOrderMoney:
                    this.orderDetail.shopsOldMoney >
                      this.orderDetail.shopsMoney
                      ? this.orderDetail.shopsOldMoney +
                      initializeShops * initializeShopsPrice
                      : '',
                  discounted: undefined
                })
              },
              rules: [
                {
                  required: true,
                  message: '请输入门店个数',
                  trigger: ['blur', 'change']
                }
              ]
            }
            : null,
          saasProductPossPrices?.[0]?.possPrice && isManagePoss
            ? {
              model: 'possNumber',
              type: 'input-number',
              label: '站点个数',
              data: saasProductPossPrices,
              min: initializePoss > 0 ? initializePoss : 1, // 为0的时候，默认1个，大于0时则为初始用户
              max: maxPoss,
              initializePoss: initializePoss,
              initializePossPrice: initializePossPrice,
              precision: 0,
              append: {
                type: 'xyt-users',
                show: true,
                isInitial: true,
                getPrice: () => ({
                  showPossPrice: this.orderDetail.showPossPrice,
                  unit: this.orderDetail.unit,
                  orderMoney:
                    this.orderDetail.possMoney +
                    initializePoss * initializePossPrice,
                  oldOrderMoney:
                    this.orderDetail.possOldMoney > this.orderDetail.possMoney
                      ? this.orderDetail.possOldMoney +
                      initializePoss * initializePossPrice
                      : '',
                  discounted: undefined
                })
              },
              rules: [
                {
                  required: true,
                  message: '请输入站点个数',
                  trigger: ['blur', 'change']
                }
              ]
            }
            : null
        ]

        this.productFormOption = [
          {
            model: 'duration',
            type: 'radio-button',
            label: '购买时长',
            rules: [
              {
                required: true,
                message: '请选择购买时长',
                trigger: ['blur', 'change']
              }
            ],
            optionData: this.yearsData,
            data: ''
          },
          ...userShops.filter(x => x),
          {
            model: 'linkMan',
            type: 'input',
            style: { width: 340 },
            label: '联系人',
            rules: [
              {
                required: true,
                message: '请输入联系人',
                trigger: ['blur', 'change']
              }
            ]
          },
          {
            model: 'linkManTel',
            type: 'tel',
            style: { width: 340 },
            label: '联系人电话',
            rules: [
              {
                required: true,
                message: '请输入联系人电话',
                trigger: ['blur', 'change']
              },
              {
                pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                message: '请输入正确的手机号码',
                trigger: 'blur'
              }
            ]
          },
          {
            model: 'money',
            type: 'text-append',
            label: '合计',
            readonly: true
          }
        ]
        console.log('dddddd', value)
        if (!value) {
          value = 0
        }
        this.productForm = {
          duration: durationValue, // 购买时长
          userNumber: initializeUsers,
          shopNumber: initializeShops,
          possNumber: initializePoss,
          linkMan: isDuration ? this.productForm.linkMan : '',
          linkManTel: isDuration ? this.productForm.linkManTel : '',
          money: '0',
          productPrice: payModelItems[value].productPrice,
          productOldPrice: payModelItems[value].productOldPrice,
        }
        console.log('sssssssss', this.productForm)
        try {
          this.$refs.myForm.$refs.value.resetFields()
        } catch (error) { }
        this.showForm = true
      } catch (error) {
        console.log('Errrrrr', error)
      }
    },
    async updateStep(next = false) {
      const clearTimer = () => clearInterval(this.timer);
      if ((this.step === 1 && !next) || (this.step === 4 && next)) {
        return this.$emit("update:active", 1);
        // payModelType
      }
      if (this.step === 1) {
        try {
          await this.$refs.myForm.$refs.value.validate();
        } catch (error) {
          return this.$message.error(Object.values(error)[0][0].message);
        }
      } else if (this.step === 2 && !this.checked && next) {
        return this.$message.warning("请阅读并同意合同条款");
      } else if (this.step === 2 && next) {
        const loading = this.$loading({
          lock: true,
          text: "订单提交中...",
          target: document.querySelector(".app-main"),
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.0)",
        });
        // 2 创建订单
        const {
          orderMoney = 0,
          oldOrderMoney = 0,
          payModelId = 0,
          productId = 0,
          shopsPriceId = '',
          usersPriceId = '',
          possPriceId = '',
          shopsQty = 0,
          usersQty = 0,
          possQty = 0,
          linkMan = "",
          linkManTel = "",
        } = this.orderDetail;

        // 加上时间戳 防止重复
        // orderNo = orderNo + Math.round(new Date())
        // 3 获取支付码
        try {
          const cOrderRes = await this?.options?.generateOrderApi({
            orderMoney,
            oldOrderMoney,
            payModelId,
            productId,
            linkMan,
            linkManTel,
            shopsPriceId,
            usersPriceId,
            possPriceId,
            shopsQty,
            usersQty,
            possQty,
          });
          let orderNo = cOrderRes.data;
          console.log('cOrderRes', cOrderRes);
          if (cOrderRes?.code === 200) {
            console.log('cOrderRes1', cOrderRes);
            const imgRes = await this?.options?.orderPayApi({
              ordNo: orderNo,
              amt: orderMoney,
              subject: `${this.appTypeName}开通`,
            });
            this.QRImgUrl = imgRes.data;
          }
          // 每秒检测一次
          this.timer = setInterval(async () => {
            // 一分钟计时
            if (this.timeing <= 60) {
              const res = await this?.options?.getOrderStateApi({
                ordNo: orderNo,
              });
              if (res?.data) {
                // 支付成功
                clearTimer();
                this.step = 4;
              }
            } else {
              clearTimer();
              this.$message.warning("支付超时！");
              this.$emit("update:active", 1);
            }
            this.timeing = this.timeing + 1;
          }, 1000);
          loading.close();
        } catch (error) {
          return loading.close();
        }
      }
      this.step = this.step + (next ? 1 : -1);
      if (this.step !== 3) {
        clearTimer();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.buy-step-com {
  padding: 6px 10px;
  ::v-deep .my-descriptions-label {
    width: 100px;
    text-align: right;
    display: block;
  }

  ::v-deep .is-process {
    color: #c0c4c0 !important;

    .el-step__icon {
      border: 2px solid #c0c4c0 !important;
    }
  }

  .top-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
  }

  .pay-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;

    .icon {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      margin-right: 3px;
    }

    .QRImgUrl {
      padding: 20px;
      border-radius: 30px;
      overflow: hidden;
      border: 2px solid #b3d9e9;
    }
  }
}
</style>
