var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        overflow: "hidden",
        padding: "0px",
        display: "flex",
        "align-items": "center",
      },
    },
    [
      !_vm.isHiddenBtns
        ? _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.nowPrint()
                },
              },
            },
            [_vm._v(_vm._s(_vm.btnText) + " ")]
          )
        : _vm._e(),
      _c("Dialog", {
        ref: "dialog",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }