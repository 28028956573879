var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-radio-group",
    _vm._b(
      {
        on: { change: _vm.change },
        model: {
          value: _vm.tiemVal,
          callback: function ($$v) {
            _vm.tiemVal = $$v
          },
          expression: "tiemVal",
        },
      },
      "el-radio-group",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.groupData, function (radio, i) {
      return _c("el-radio-button", { key: i, attrs: { label: radio.label } }, [
        _vm._v(" " + _vm._s(radio.text) + " "),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }