<template>
  <el-form
    v-if="formData"
    v-bind="$attrs"
    ref="value"
    :model="formData"
    @submit.native.prevent
    size="mini"
  >
    <el-form-item
      :label="item.option ? item.option.label : ''"
      v-for="(item, i) in options"
      :key="`${item.model}.${i}`"
      :rules="item.rules"
      :prop="item.model"
    >
      <!-- 文字输入框 -->
      <el-input
        v-if="['input', 'textarea'].includes(item.type)"
        v-model="formData[item.model]"
        :type="item.type || 'text'"
        :placeholder="item.option.tip || `请输入${item.option.label}`"
        :disabled="item.option.disabled"
        :style="`width: ${item.option.style ? item.option.style.width : ''}px`"
        :rows="item.option.minRows || 3"
        :maxlength="item.option.maxlength"
        :minlength="item.option.minlength"
        :show-word-limit="item.option.maxlength && item.option.maxlength > 0"
        :show-password="item.option.showPassword"
        :readonly="item.option.readonly"
        :prefix-icon="item.option.prefixIcon"
        :suffix-icon="item.option.suffixIcon"
        resize="none"
        :autosize="{
          minRows: item.option.minRows || 3,
          maxRows: item.option.maxRows || 6,
        }"
      />
      <!-- 小程序选择链接 -->
      <MinappUrl v-else-if="item.type === 'miniAppUrl'" v-model="formData.link" />
      <!-- 分割线 -->
      <el-divider v-else-if="item.type === 'divider'" />
      <IconButtons
        v-else-if="item.type === 'icon-buttons'"
        v-model="formData[item.model]"
        :label="item.option.buttonLabel"
        :dictType="item.option.dictType"
        :dataKey="item.option.dataKey"
        :hideText="item.option.hideText"
      />
      <div class="x-bc button-view" v-else-if="['colors', 'slider'].includes(item.type)">
        <div class="x-f">
          <span class="marR20 label">{{ item.option.buttonLabel }}</span>
          <span v-if="['colors'].includes(item.type)">{{ formData[item.model] }}</span>
        </div>
        <div class="x-f btnListBox">
          <el-color-picker
            v-if="item.type === 'colors'"
            v-model="formData[item.model]"
            :predefine="SYS_COLORS"
          />
          <el-slider
            v-else-if="item.type === 'slider'"
            v-model="formData[item.model]"
            :min="0"
            :max="30"
            style="width: 100%"
          />
        </div>
      </div>
      <Mofang
        v-else-if="item.type === 'mofang'"
        :data="value"
        class="mofang"
        edit
        @change="change"
      />
      <div
        v-else-if="item.type === 'imgs' && formData.imgList[viewIndex]"
        class="el-card item padd15 y-c marB20"
      >
        <div class="row x-start">
          <div class="imgBox x-c cursorP" @click="addImgClick()">
            <div class="y-c" v-if="formData.imgList[viewIndex].image == ''">
              <i class="el-icon-plus" style="font-size: 20px; margin-bottom: 5px"></i>
              <span>添加图片</span>
            </div>
            <el-image
              v-else-if="formData.imgList[viewIndex].image != ''"
              :src="formData.imgList[viewIndex].image"
              width="100%"
            />
          </div>
          <div class="inputBox marL10 x-f1 y-start">
            <div class="x-b" style="width: 100%">
              <div>链接设置</div>
            </div>
            <MinappUrl v-model="formData.imgList[viewIndex].link" />
          </div>
        </div>
        <!-- <div class="addBtnBox x-c">
          <div class="addBtn x-f cursorP" @click="addBtn">
            <i class="el-icon-plus"></i>
            <span>添加图片</span>
            <span>( {{ data.style.imgList.length }} /10 )</span>
          </div>
        </div> -->
      </div>
    </el-form-item>

    <el-dialog
      title="素材库"
      :visible.sync="sucaikuShow"
      width="80%"
      :destroy-on-close="true"
    >
      <Material :isDialog="true" @accessUrlListCom="getAccessUrlList" :isRadio="true" />
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="imgUpdateSubmit">确 定</el-button>
        <el-button @click="imgUpdateCancel">取 消</el-button>
      </div>
    </el-dialog>
  </el-form>
</template>
<script>
import OssUpload from "@/components/oss-upload/index.vue";
import { SYS_COLORS } from "@/utils/constant-o2o.js";
import { validateTelephone } from "@/api/codeRule";

import Mofang from "@/views/O2OMall/config/template/decoratePage/components/conterTemplate/mofang.vue";
import IconButtons from "@/components/my-sys-form/icon-buttons/index.vue";
import MinappUrl from "@/components/my-sys-form/minapp-url/index.vue";
import Material from "@/views/components/material/index.vue"; //素材库
import { cloneDeep, isEqual } from "lodash";
import { getImgInfo } from "@/utils/index.js";
export default {
  name: "mySysForm",
  components: {
    OssUpload,
    IconButtons,
    Mofang,
    MinappUrl,
    Material,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: "value",
    event: "modelEmit",
  },
  watch: {
    formData: {
      handler(val, oldVal) {
        console.log("valval", val, oldVal);
        const newVal = cloneDeep(this.value);
        Object.keys(val).forEach((key) => {
          const models = key.split("$$");
          let value = {};
          models.forEach((item, i) => {
            if (i === 0) {
              if (i === models.length - 1) {
                newVal[item] = val[key];
              } else {
                value = newVal[item];
              }
            } else if (i === models.length - 1) {
              value[item] = val[key];
            } else {
              value = value[item];
            }
          });
        });
        this.$emit("modelEmit", cloneDeep(newVal));
        this.$emit("change", newVal);
      },
      deep: true,
    },
    value: {
      handler(val, oldVal) {
        if (!isEqual(val, oldVal)) {
          const formData = {};
          this.options.forEach((x) => {
            if (!["divider"].includes(x) && x.model) {
              const models = x.model.split("$$");
              let value = null;
              models.forEach((item, i) => {
                value =
                  (value ? value : cloneDeep(this.value))[item] ||
                  (i === models.length - 1 ? "" : {});
              });
              formData[x.model] = value;
            }
          });
          if (!isEqual(this.formData, formData)) {
            this.formData = formData;
          }
        }
      },
      immediate: true, //首次加载的时候执行函数
      deep: true,
    },
  },
  data() {
    return {
      viewIndex: 0,
      formData: null,
      openLinkType: {},
      openLink: false,
      SYS_COLORS,

      sucaikuShow: false,
      accessUrlList: [],
    };
  },
  methods: {
    change(e) {
      this.viewIndex = e;
    },
    addImgClick() {
      this.sucaikuShow = true;
    },
    // 选择图片弹框确定
    async imgUpdateSubmit() {
      if (this.accessUrlList.length > 1) {
        return this.$message.error("图片信息只能单选");
      }
      try {
        const image = this.accessUrlList[0];
        const res = await getImgInfo(image);
        const scale = res.width / res.height;
        const arr = this.formData.imgList
          .filter((x, i) => (x.scale ? x.scale : i === this.viewIndex))
          .map((x, i) => (i === this.viewIndex ? scale : x.scale));
        this.formData.imgList = this.formData.imgList.map((x, i) => ({
          ...x,
          image: i === this.viewIndex ? image : x.image,
          scale: i === this.viewIndex ? scale : x.scale,
          minScale: Math.min(...arr),
          maxScale: Math.max(...arr),
        }));
        this.accessUrlList = [];
        this.sucaikuShow = false;
      } catch (error) {
        console.log("error", error);
        this.$message.error("选择失败，请重试！");
      }
    },
    //上传图片弹窗取消
    imgUpdateCancel() {
      this.accessUrlList = [];
      this.sucaikuShow = false;
    }, // 选择图片素材
    getAccessUrlList(value) {
      console.log("选择的图片：", value);
      this.accessUrlList = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  font-size: 14px;
  line-height: 18px;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}
.btnListBox {
  border-radius: 5px;
  flex: 1;
  justify-content: flex-end;
  .item {
    //width: 38px;
    height: 30px;
    border: 1px solid #ebedf0;
    padding: 0 20px;
    cursor: pointer;
  }
  .borderColor {
    border: 1px solid #1c75f4;
  }
}

.mofang {
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
}

.row {
  display: felx;
  align-items: center;
  width: 100%;
  .imgBox {
    height: 80px;
    width: 80px;
    border: 1px solid #f5f5f5;
  }
}
</style>
