var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        overflow: "hidden",
        padding: "0px",
        display: "flex",
        "align-items": "center",
      },
    },
    [
      _c(
        "el-dropdown",
        {
          attrs: {
            "split-button": "",
            type: _vm.item.type || "warning",
            icon: _vm.item.icon || "el-icon-circle-check",
            placement: _vm.item.placement || "bottom",
            trigger: _vm.item.trigger || "click",
            size: "mini",
          },
          on: {
            click: function ($event) {
              return _vm.handleEvent(_vm.item)
            },
            command: _vm.handleEvent,
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.item.label) + " "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _vm.item.print
                ? _c("div", { staticClass: "table-page-print-view" }, [
                    _c(
                      "div",
                      { staticStyle: { padding: "0 8px" } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: { click: _vm.addPrint },
                          },
                          [_vm._v("新建模板")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "print-view-sorcll" },
                      [
                        _vm.item.print.data
                          ? _vm._l(
                              _vm.item.print.data,
                              function (printItem, i) {
                                return _c(
                                  "div",
                                  { key: i, staticClass: "print-view-list" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "print-view-list-title",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEvent(
                                              _vm.item,
                                              printItem
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-check",
                                          style: {
                                            visibility: printItem.isDefault
                                              ? "visible"
                                              : "hidden",
                                            marginRight: "3px",
                                            color: "#1890ff",
                                          },
                                        }),
                                        _vm._v(
                                          _vm._s(printItem.printTemplateName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "print-view-list-botton" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.updatePrint(
                                                  _vm.item.print,
                                                  printItem
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 编辑 ")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.lookPrint(
                                                  _vm.item.print,
                                                  printItem
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 预览 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            )
                          : _c("div", { staticClass: "print-view-loading" }, [
                              _c("span", [
                                _vm._v("加载中 "),
                                _c("i", { staticClass: "el-icon-loading" }),
                              ]),
                            ]),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm.printConfig
        ? _c("PrintTemplateDetail", {
            ref: "PrintTemplateDetail",
            staticStyle: {
              position: "fixed",
              left: "-9999999px",
              top: "-9999999px",
              "z-index": "1",
              width: "100vw",
              height: "100vh",
            },
            attrs: { isEdit: false, printConfig: _vm.printConfig },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }