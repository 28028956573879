<template>
  <div style="overflow: hidden; padding: 0px; display: flex; align-items: center">
    <el-button v-if="!isHiddenBtns" type="primary" @click="nowPrint()" size="mini">{{btnText}} </el-button>
    <!-- 弹窗 -->
    <Dialog ref="dialog" :options.sync="dialogOptions" />
  </div>
</template>
<script>
import { deepCopy } from '@/utils'
import { getTemplateList } from '@/api/system/system/printTemplate'
import printConfig from '@/components/tablePage/tableButtons/printConfig.js'

export default {
  name: 'TabelPageTableTopButton',
  components: {
    Dialog: () => import('@/components/Dialog')
  },
  model: { prop: 'value', event: 'Device' },
  props: {
    billId: {
      type: [String, Number],
      default: ''
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    isHiddenBtns: {
      type: Boolean,
      default: false
    },
    isHome: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      default: '直接打印'
    },
    dialogType: {
      type: String,
      default: 'PrintTemplateNowPrint'
    },
  },
  data() {
    return {
      dialogOptions: {
        show: false,
        type: 'PrintTemplateNowPrint',
        width: '80vw',
        title: '直接打印',
        data: ''
      }
    }
  },
  methods: {
    // 直接打印
    nowPrint() {
      let activeMenu = this.isHome ? this.$route.path : this.$route.meta?.activeMenu
      console.log('filters', activeMenu);
      this.dialogOptions.type =  this.dialogType
      this.dialogOptions.data = {
        ...printConfig[activeMenu],
        filters: this.filters,
        check: [{ billId: this.billId }]
      }
      this.dialogOptions.show = true
    }
  }
}
</script>
<style lang="scss" scoped>
.table-button-popper {
  padding: 0;
  .table-button-popper-content {
    text-align: center;
    margin: 0;
    padding: 4px 0;
    .table-button-popper-item {
      cursor: pointer;
      height: 30px;
      line-height: 30px;
      &:hover {
        background-color: #e8f4ff;
        color: #46a6ff;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.el-form-myde {
  background-color: #f8f8f9;
  padding: 8px 0;
  margin-bottom: 10px;
  min-height: 48px;
  clear: both;

  ::v-deep .el-form-item {
    &:last-child {
      margin-right: 0;
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 0;
  height: 28px;
}
.el-dropdown {
  //审核按钮
  &.audit {
    margin-left: 10px;
    margin-right: 5px;
    ::v-deep.el-button-group > .el-button {
      background-color: #3cbf1d !important;
      color: #fff;
    }
    ::v-deep.el-button-group > .el-button:hover {
      background-color: #60c947 !important;
    }
  }
  //启用按钮
  &.startUsing {
    margin-left: 5px;
    margin-right: 10px;
    ::v-deep.el-button-group > .el-button {
      background-color: #ff8a00 !important;
      color: #fff;
    }
    ::v-deep.el-button-group > .el-button:hover {
      background-color: #f9a13a !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.table-page-print-view {
  padding: 3px 0px;
  background-color: #ffffff;
  position: relative;
  .print-view-sorcll {
    height: 160px;
    width: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    .print-view-list {
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333333;
      &:hover {
        background-color: #f8f8f9;
      }
      /* 垂直滚动条样式 */
      /* 宽度 */
      &::-webkit-scrollbar {
        width: 8px;
      }
      /* 背景色 */
      &::-webkit-scrollbar-track {
        background-color: #ffffff;
      }
      /* 滑块颜色 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.5);
      }
      .print-view-list-title {
        width: 100%;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
      }
      .print-view-list-botton {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
      }
    }
  }
  .print-view-loading {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #858585;
  }
}
</style>
