<template>
  <div class="customTotal" v-if="customTotal">
    <span v-for="(item, index) of customTotal.list" :key="index">
      {{ item.title }}: {{ item.value }}
    </span>
  </div>
</template>

<script>
export default {
  name: "CustomTotal", // 本年内 月日 选择器
  props: {
    value: { required: true },
    body: {
      type: [Object, Array, String, Number],
    },
    total: {
      type: Number,
      default: 0,
    },
    // 是否禁用
  },
  model: { prop: "value", event: "Device" },
  data() {
    return {};
  },
  computed: {
    customTotal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("Device", val);
      },
    },
  },
  methods: {
    async getList() {
      await this.$nextTick();
      if (this.customTotal.apiHost) {
        const customTotal = await this.customTotal.apiHost(this.body);
        for (let i in customTotal) {
          const find = this.customTotal.list.find((item) => item.key === i);
          find.value = customTotal[i];
        }
      }
      if (this.customTotal.data) {
        this.customTotal.list.forEach((item) => {
          item.value = this.total;
        });
      }
      this.$emit("onload");
    },
  },
};
</script>

<style lang="scss" scoped>
.customTotal {
  background-color: #f5f7fa;
  padding: 15px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  span {
    font: {
      size: 13px;
    }
    margin-right: 30px;
  }
}
</style>
