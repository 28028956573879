var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapList" },
    [
      _c("TablePage", {
        ref: "tablePage",
        on: { handleEvent: _vm.handleEvent },
        scopedSlots: _vm._u(
          [
            {
              key: "product",
              fn: function (ref) {
                var row = ref.row
                return row
                  ? [
                      _c("div", { staticStyle: { "font-size": "12px" } }, [
                        _c("div", [_vm._v("产品：" + _vm._s(row.productName))]),
                        _c("div", [_vm._v("商户：" + _vm._s(row.tenantName))]),
                        _c("div", [
                          _vm._v("计费模式：" + _vm._s(row.productCalcMode)),
                        ]),
                        _c("div", [
                          _c("div", [
                            _vm._v("门店数：" + _vm._s(row.shops) + "个"),
                          ]),
                          _c("div", { staticClass: "text-left" }, [
                            _vm._v("单价：" + _vm._s(row.shopsOldPrice) + "元"),
                          ]),
                          _c("div", { staticClass: "text-left" }, [
                            _vm._v("折扣：" + _vm._s(row.shopsDiscount) + "元"),
                          ]),
                          _c("div", { staticClass: "text-left" }, [
                            _vm._v(
                              "实付金额：" + _vm._s(row.shopsPrice) + "元"
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c("div", [
                            _vm._v("用户数数：" + _vm._s(row.users) + "个"),
                          ]),
                          _c("div", { staticClass: "text-left" }, [
                            _vm._v("单价：" + _vm._s(row.usersOldPrice) + "元"),
                          ]),
                          _c("div", { staticClass: "text-left" }, [
                            _vm._v("折扣：" + _vm._s(row.usersDiscount) + "元"),
                          ]),
                          _c("div", { staticClass: "text-left" }, [
                            _vm._v(
                              "实付金额：" + _vm._s(row.usersPrice) + "元"
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _vm._v("合计：" + _vm._s(row.orderPayMoney)),
                        ]),
                      ]),
                    ]
                  : undefined
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.options,
          callback: function ($$v) {
            _vm.options = $$v
          },
          expression: "options",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }