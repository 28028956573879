var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.formData
    ? _c(
        "el-form",
        _vm._b(
          {
            ref: "value",
            attrs: { model: _vm.formData, size: "mini" },
            nativeOn: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          "el-form",
          _vm.$attrs,
          false
        ),
        [
          _vm._l(_vm.options, function (item, i) {
            return _c(
              "el-form-item",
              {
                key: item.model + "." + i,
                attrs: {
                  label: item.option ? item.option.label : "",
                  rules: item.rules,
                  prop: item.model,
                },
              },
              [
                ["input", "textarea"].includes(item.type)
                  ? _c("el-input", {
                      style:
                        "width: " +
                        (item.option.style ? item.option.style.width : "") +
                        "px",
                      attrs: {
                        type: item.type || "text",
                        placeholder:
                          item.option.tip || "请输入" + item.option.label,
                        disabled: item.option.disabled,
                        rows: item.option.minRows || 3,
                        maxlength: item.option.maxlength,
                        minlength: item.option.minlength,
                        "show-word-limit":
                          item.option.maxlength && item.option.maxlength > 0,
                        "show-password": item.option.showPassword,
                        readonly: item.option.readonly,
                        "prefix-icon": item.option.prefixIcon,
                        "suffix-icon": item.option.suffixIcon,
                        resize: "none",
                        autosize: {
                          minRows: item.option.minRows || 3,
                          maxRows: item.option.maxRows || 6,
                        },
                      },
                      model: {
                        value: _vm.formData[item.model],
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, item.model, $$v)
                        },
                        expression: "formData[item.model]",
                      },
                    })
                  : item.type === "miniAppUrl"
                  ? _c("MinappUrl", {
                      model: {
                        value: _vm.formData.link,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "link", $$v)
                        },
                        expression: "formData.link",
                      },
                    })
                  : item.type === "divider"
                  ? _c("el-divider")
                  : item.type === "icon-buttons"
                  ? _c("IconButtons", {
                      attrs: {
                        label: item.option.buttonLabel,
                        dictType: item.option.dictType,
                        dataKey: item.option.dataKey,
                        hideText: item.option.hideText,
                      },
                      model: {
                        value: _vm.formData[item.model],
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, item.model, $$v)
                        },
                        expression: "formData[item.model]",
                      },
                    })
                  : ["colors", "slider"].includes(item.type)
                  ? _c("div", { staticClass: "x-bc button-view" }, [
                      _c("div", { staticClass: "x-f" }, [
                        _c("span", { staticClass: "marR20 label" }, [
                          _vm._v(_vm._s(item.option.buttonLabel)),
                        ]),
                        ["colors"].includes(item.type)
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.formData[item.model])),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "x-f btnListBox" },
                        [
                          item.type === "colors"
                            ? _c("el-color-picker", {
                                attrs: { predefine: _vm.SYS_COLORS },
                                model: {
                                  value: _vm.formData[item.model],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.model, $$v)
                                  },
                                  expression: "formData[item.model]",
                                },
                              })
                            : item.type === "slider"
                            ? _c("el-slider", {
                                staticStyle: { width: "100%" },
                                attrs: { min: 0, max: 30 },
                                model: {
                                  value: _vm.formData[item.model],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, item.model, $$v)
                                  },
                                  expression: "formData[item.model]",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : item.type === "mofang"
                  ? _c("Mofang", {
                      staticClass: "mofang",
                      attrs: { data: _vm.value, edit: "" },
                      on: { change: _vm.change },
                    })
                  : item.type === "imgs" && _vm.formData.imgList[_vm.viewIndex]
                  ? _c(
                      "div",
                      { staticClass: "el-card item padd15 y-c marB20" },
                      [
                        _c("div", { staticClass: "row x-start" }, [
                          _c(
                            "div",
                            {
                              staticClass: "imgBox x-c cursorP",
                              on: {
                                click: function ($event) {
                                  return _vm.addImgClick()
                                },
                              },
                            },
                            [
                              _vm.formData.imgList[_vm.viewIndex].image == ""
                                ? _c("div", { staticClass: "y-c" }, [
                                    _c("i", {
                                      staticClass: "el-icon-plus",
                                      staticStyle: {
                                        "font-size": "20px",
                                        "margin-bottom": "5px",
                                      },
                                    }),
                                    _c("span", [_vm._v("添加图片")]),
                                  ])
                                : _vm.formData.imgList[_vm.viewIndex].image !=
                                  ""
                                ? _c("el-image", {
                                    attrs: {
                                      src: _vm.formData.imgList[_vm.viewIndex]
                                        .image,
                                      width: "100%",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "inputBox marL10 x-f1 y-start" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "x-b",
                                  staticStyle: { width: "100%" },
                                },
                                [_c("div", [_vm._v("链接设置")])]
                              ),
                              _c("MinappUrl", {
                                model: {
                                  value:
                                    _vm.formData.imgList[_vm.viewIndex].link,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData.imgList[_vm.viewIndex],
                                      "link",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.imgList[viewIndex].link",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "素材库",
                visible: _vm.sucaikuShow,
                width: "80%",
                "destroy-on-close": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.sucaikuShow = $event
                },
              },
            },
            [
              _c("Material", {
                attrs: { isDialog: true, isRadio: true },
                on: { accessUrlListCom: _vm.getAccessUrlList },
              }),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.imgUpdateSubmit },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c("el-button", { on: { click: _vm.imgUpdateCancel } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }