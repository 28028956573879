<template>
  <div class="x-bc button-view">
    <div class="x-f">
      <span class="marR20 label">{{ label }}</span>
      <span v-if="!hideText">{{ text }}</span>
    </div>
    <div class="x-f btnListBox">
      <div
        class="item x-c"
        v-for="(item, index) in dicts[dictType]"
        :key="index"
        :class="{ borderColor: modelValue == item[dataKey] }"
        @click="changeValue(item)"
      >
        <iconpark-icon
          v-if="item.name"
          :name="item.name"
          size="16px"
          :style="{ [dataKey]: modelValue }"
        />
        <span v-else>{{ item.text }} </span>
      </div>
    </div>
  </div>
</template>
<script>
import * as dicts from "@/utils/constant-o2o.js";
export default {
  name: "IconButtons",
  model: {
    prop: "value",
    event: "modelValue",
  },
  props: {
    dictType: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [Array, Object, String, Number],
      default: "",
    },
    hideText: {
      type: Boolean,
      default: false,
    },
    dataKey: {
      type: String,
      default: "style",
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("modelValue", e);
      },
    },
  },
  data() {
    return {
      text: "",
      dicts,
    };
  },
  mounted() {
    const data = this.dicts[this.dictType];
    console.log("this.modelValue", data, this.modelValue);
    if (!this.modelValue && Array.isArray(data) && data.length) {
      this.text = data[0].text;
      this.modelValue = data[0][this.dataKey];
    } else {
      this.text = data?.find?.((x) => x[this.dataKey] === this.modelValue)?.text || "";
    }
  },
  methods: {
    changeValue(item) {
      this.text = item.text;
      this.modelValue = this.dataKey ? item[this.dataKey] : item;
    },
  },
};
</script>
<style lang="scss" scoped>
.button-view {
  .lable {
    margin-right: 16px;
    font-size: 14px;
    color: #d3d3d3;
    line-height: 18px;
    white-space: nowrap;
  }
  .btnListBox {
    border-radius: 5px;
    .item {
      //width: 38px;
      height: 30px;
      border: 1px solid #ebedf0;
      padding: 0 20px;
      cursor: pointer;
    }
    .borderColor {
      border: 1px solid #1c75f4;
    }
  }
}
</style>
