var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "x-bc button-view" }, [
    _c("div", { staticClass: "x-f" }, [
      _c("span", { staticClass: "marR20 label" }, [_vm._v(_vm._s(_vm.label))]),
      !_vm.hideText ? _c("span", [_vm._v(_vm._s(_vm.text))]) : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "x-f btnListBox" },
      _vm._l(_vm.dicts[_vm.dictType], function (item, index) {
        var _obj
        return _c(
          "div",
          {
            key: index,
            staticClass: "item x-c",
            class: { borderColor: _vm.modelValue == item[_vm.dataKey] },
            on: {
              click: function ($event) {
                return _vm.changeValue(item)
              },
            },
          },
          [
            item.name
              ? _c("iconpark-icon", {
                  style:
                    ((_obj = {}), (_obj[_vm.dataKey] = _vm.modelValue), _obj),
                  attrs: { name: item.name, size: "16px" },
                })
              : _c("span", [_vm._v(_vm._s(item.text) + " ")]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }