<template>
  <el-select
    v-model="inSelect"
    class="sys-search"
    multiple
    :reserve-keyword="option.reserveKeyword || true"
    :multiple-limit="option.multipleLimit || 0"
    :clearable="option.clearable || true"
    v-bind="$attrs"
    @change="change"
  >
    <template v-for="(item, i) in data">
      <el-option
        v-if="!item.hide"
        :key="item.value + item.label"
        :disabled="i === 0 && data.length === 1"
        :label="item.label"
        :value="item.value"
      />
    </template>
  </el-select>
</template>
<script>
import { deepCopy } from "@/utils";
export default {
  name: "ComponenetTableSearch",
  model: { prop: "model", event: "Device" },
  props: {
    model: { type: [Array, Object, String, Number] },
    data: { type: Array, default: () => [] },
    join: { type: String, default: "" }, // '' 空字符返回数组
    moveAll: { type: Boolean, default: true } // 全选是否移除内容 join为空等同true
  },
  data() {
    return {
      select: []
    };
  },
  computed: {
    allContent() {
      return this.data[0].value; // 全部内容得 value
    },
    inSelect: {
      get() {
        return this.select;
      },
      set(value) {
        let val = value;
        const { data } = this;
        const oldAll = this.select.includes(this.allContent);
        if (val.includes(this.allContent)) {
          if (!oldAll) {
            val = data.slice(1).map(x => x.value);
          } else {
            val.splice(val.indexOf(this.allContent), 1);
          }
        } else {
          val = oldAll ? [] : val;
        }
        this.setData(
          val,
          this.join && this.moveAll && val.length === data.length - 1
        );
      }
    }
  },
  watch: {
    model: {
      handler(val) {
        if (Array.isArray(val)) {
          this.setData(val);
        }
      },
      immediate: true
    }
  },
  methods: {
    change() {
      this.$emit("select-change", this.inSelect); // 包括全部
    },
    setData(val, isMove = false) {
      const value = isMove ? [] : val;
      this.$emit("Device", this.join ? value.join(this.join) : value);
      const data = deepCopy(val);
      if (data.length === this.data.length - 1) {
        data.unshift(this.allContent); // 补全全选
      }
      this.select = data;
    }
  }
};
</script>
<style lang="scss" scoped>
.sys-search {
  width: 100%;
  .el-select__tags {
    height: 24px;
    overflow: hidden;
  }
}
</style>
